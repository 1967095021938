﻿.link {
	text-decoration: none !important;

	&::after {
		font-style: normal;
		font-size: 20px;
		position: relative;
		top: 2px;
		margin: 0 0 0 7px;
	}

	&-arrow::after {
		font-family: 'Font Awesome 5 Pro';
		content: '\f0a9';
	}

	&-download::after {
		font-family: 'Font Awesome 5 Pro';
		content: '\f0ab';
	}

	&-external::after {
		font-family: 'Font Awesome 5 Pro';
		content: '\f35d';
	}

	&-chevron::after {
		content: '›';
	}
}
