﻿.three-icon-actions {

	.boxout {
		.content {
			p {
				padding-left: 0;
			}
		}
	}
}
