.carousel {
	&-caption {
		&-fluid {
			width: 470px;
		}

		&-inner {
			width: 350px;
		}
	}
}
