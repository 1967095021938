.breadcrumb {
	padding: 0;
	margin-bottom: 0;
	background-color: transparent;
	border-radius: 0;

	&-item {
		display: none;
	}

	&-item {
		+ &-item {
			&::before {
				color: #6c757d;
				content: "›";
			}
		}

		& a {
			color: #6c757d;
		}

		&.active {
			color: #212529;
			font-weight: bolder;
		}
	}
}
