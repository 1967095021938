﻿.phone-numbers {
	.number-block {
		border: 1px solid $grey;
		text-align: center;
		background: url(../assets/img/icons/phone.svg) no-repeat;
		background-position: center 30px;
		background-size: 82px 82px;
		height: 100%;
		overflow-wrap: break-word;

		&:hover {
			border-color: $dark-grey;
		}

		a {
			display: block;
			text-decoration: none;
		}

		.label {
			color: $black;
			padding: 120px 0 0 0;
			font-size: 2rem;
		}

		.number {
			color: $green;
			padding: 0 0 20px 0;
			margin: 0;
		}
	}
}
