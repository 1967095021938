﻿[dir="rtl"] {
	header {
		.top-nav {
			.navbar {
				.search {
					.input-group {
						left: 0;
					}
				}
			}
		}
	}

	.wsmenu02 {
		> .wsmenu02-list {
			> li {
				> .wsmegamenu02 {
					margin-top: 25px;
					text-align: right !important;

					> li {
						padding-right: 0 !important;

						a {
							padding-right: 10px;
							background: transparent;
						}

						.sub-menu02 {
							padding-right: 0;

							> li {

								.sub-menu02 {
									li {
										margin-right: 0;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.wsanimated-arrow02 {
		padding: 16px 22px 16px 0px;
	}
}
