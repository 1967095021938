﻿[dir='rtl'] {
	.location-details {
	  .only-desktop {
		.column-right {
		  padding: 0 1.5% 0 0;
		}
		.column-left {
		  padding: 0 0 0 1.5%;
		}
	  }
	}
  }
  