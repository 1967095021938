﻿.findUs{
	padding-top:1rem;
	padding-bottom:1rem;
	border-top:1px solid rgba(0,0,0,.1);
	border-bottom:1px solid rgba(0,0,0,.1);
	.map-wrap{
		display:flex;
		align-items:center;
	}
	.info-wrap{
		.btn{
			margin-left:0;
			min-width:70%;
			display:inline-flex;
			justify-content:space-between;
			align-items:center;
			margin-bottom:1rem;
			font-size:0.9em;
		}
		h3+p{
			font-size:0.9rem;
		}
	}
	.btn-title{
		margin: 0.5rem 0;
	}
	.link-external{
		margin-left:0;
		min-width:70%;
		display:inline-flex;
		justify-content:center;
		align-items:center;
		margin-bottom:1rem;
		font-size:0.9em;
		background-color:$blue;
		border-radius:500px;
		padding: 2px 17px 5px 18px;
		line-height: 2;
		color:#fff;
		&:after {
			font-style: normal;
			font-size: 0.9rem;
			position: relative;
			top: 0px;
			margin: 0 0 0 7px;
		}
	}
	.map{
		margin-bottom:1.5em;
	}
}

// background color exceptions

.blue .findUs .btn-title, 
.green .findUs .btn-title, 
.blue .findUs .btn-title, 
.green .findUs .btn,
.blue .findUs .btn-title, .blue .findUs .btn{
	color:#fff !important;
	border-color:#fff !important;
}

.blue .findUs, .green .findUs{
	h3+p{
		font-weight:300;
	}
	.btn{
		font-weight:300;
	}
}
.blue .findUs .link-external{
	background-color:$green;
}