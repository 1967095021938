﻿.tabbed-content {
	display: none;
	margin-bottom: 50px;

	.container-fluid {
		padding-left: 0;
		padding-right: 0;
	}

	&-title {
		color: $blue;
	}

	.header-image {
		background-size: cover;
		background-position: center;
		height: 150px;
	}

	.nav-tabs {
		border-bottom-color: $grey;

		.nav-item {
			.nav-link {
				color: $green;
				padding-bottom: 20px;
				border-top-left-radius: .1rem;
				border-top-right-radius: .1rem;

				&.active, &:hover {
					border-color: $grey $grey $white;
				}
			}

			.nav-link img {
				display: block;
				margin: 30px auto 10px;
				width: auto;
				height: 80px;
			}
		}
	}

	.nav-tabs-alt {
		border-color: $grey !important;

		.nav-item {
			.nav-link {
				color: $blue !important;
				border-top-width: 3px !important;

				&.active, &:hover {
					border-top-width: 3px !important;
					border-color: $green $grey $white !important;
				}
			}

			.nav-link img {
				margin: 10px auto 10px;
			}
		}
	}


	.tab-content {

		.panel-collapse {
			height: auto;

			&.collapse {
				display: block;
				border-bottom: 1px solid $grey;
			}
		}
	}

	&-accordions {
		display: block;
	}
}
