﻿/* please note: this module originally only allowed 3 items and was on a green background...
 * we decided to allow 4 items and introduce a colour picker...
 * however renaming the doctype alias in umbraco resulted in lost data so we decided to leave the alias and file names as is
 */
.three-icon-actions-buttons {
	.content {
		background-color: $white;
		text-align: center;
		padding: 5px;

		img {
			width: 86px;
		}

		p {
			color: $green;
		}

		a {
			display: block;
			color: $green;
		}

		a:hover {
			text-decoration: none;
		}
	}

	&-green {
		.content {
			background-color: $green;
			text-align: center;
			padding: 5px;

			img {
				margin-top: 30px;
				width: 86px;
			}

			p {
				padding-bottom: 30px;
				color: $white;
			}

			a {
				display: block;
				color: $white;
			}

			a:hover {
				text-decoration: none;
			}
		}
	}
}
