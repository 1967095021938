﻿.image-actions {

	a {
		&:hover {
			text-decoration: none;
		}
	}

	.content {
		padding: 15px 15px 1px 15px;
		background-color: $blue;

		h3 {
			color: $white;
			font-weight: 700;
		}

		p {
			color: $white;
			font-weight: 400;
		}
	}

	.boxout {
		float: left;
		position: relative;
		width: 100%;
		overflow: hidden;
		margin: 0;
		border: 0;
		height: 100%;
		background-size: cover;
		background-position: center;
		padding-bottom: 50% !important;

		&-twitter {
			//used in the _ThreeTileActionGridAndTwitterFeed (used on the homepage)
			border: 1px solid $light-grey;
			height: 500px;

			iframe {
				height: 500px !important;
			}
		}

		&-rectangle {
			padding-bottom: 50% !important;

			.content {
				height: auto !important;
			}
		}

		.content {
			position: absolute;
			width: 100%;
			background-color: rgba(41, 57, 104, 0.8);
			bottom: 0;
		}
	}

	.boxout-icon {
		/*margin-bottom: 15px;*/
		padding: 20px 20px 0px 20px;
		padding-bottom: 0 !important;

		.content {
			text-align: left;
			background-color: inherit;
			position: relative;

			figure {
				margin: 0 auto;
			}

			h2 {
				padding: 10px 0 15px 0;
				font-weight: 700;
			}
		}
	}
}
