﻿[dir="rtl"] {
	.boxouts {
		.boxout {
			.content {
				figure {
					text-align: right;
				}
			}

			&-align-center {
				.content {
					figure, h2, h3, p {
						text-align: right !important;
					}
				}
			}
		}
	}
}
