﻿.image-actions {

	.boxout {
		padding-bottom: initial;
		overflow: visible;
		height: 300px;

		&-rectangle {
			padding-bottom: initial !important;

			.content {
				height: 100% !important;
			}
		}

		.content {
			position: relative;
			width: 100%;
			height: 100%;
			padding: 15px 15px 0 15px;
		}
	}

	.boxout-icon {
		height: auto;
		padding-bottom: 0 !important;

		.content {
			p {
				padding-left: 0;
			}
		}
	}
}
