.accordions {
	background-size: cover;

	.accordion {
		.card {
			margin-bottom: 5px;

			&:not(:first-of-type):not(:last-of-type) {
				border-bottom: 1px solid $green;
			}

			&-header {
				cursor: pointer;
				padding: 0;
			}

			&-title {
				font-weight: bold;
				font-size: 1.3rem;
				padding: .75rem 2.5rem .75rem .75rem;
				display: block;
				margin: 0;

				&:hover {
					color: $white;
					text-decoration: none;
				}

				&:after {
					font-family: 'Font Awesome 5 Pro';
					content: "\f067";
					position: absolute;
					top: 12px;
					right: 13px;
					font-size: 20px;
					font-weight: 400;
				}

				&-expanded:after {
					content: "\f068";
				}
			}

			&-body {
				.header-image {
					background-size: cover;
					background-position: center;
					height: 150px;
				}
			}
		}
	}

	&-green {
		.overlay {
			background-color: rgba(45, 129, 122, 0.8);

			&-solid {
				background-color: $green;
			}
		}

		.accordions-title {
			color: $white;
		}

		.accordions-description {
			color: $white;
		}

		.accordion {
			.card {
				border-color: $green;

				&:first-of-type {
					border-bottom: 1px solid $green;
				}

				&-header {

					&:after {
						color: $green;
					}

					&.collapsed {
						&:after {
							color: $green;
						}
					}
				}

				&-title {
					color: $green;

					&:hover {
						color: $green;
					}
				}
			}
		}
	}

	&-white {
		.overlay {
			background-color: rgba(255, 255, 255, 0.8);

			&-solid {
				background-color: $white;
			}
		}

		.accordions-title {
			color: $blue;
		}

		.accordions-description {
			color: $black;
		}

		.accordion {
			.card {
				border-color: $green;

				&:first-of-type {
					border-bottom: 1px solid $green;
				}

				&-header {
					background-color: $green;
					border-color: $green;

					&:after {
						color: $white;
					}

					&.collapsed {
						&:after {
							color: $white;
						}
					}
				}

				&-title {
					color: $white;

					&:hover {
						color: $white;
					}
				}
			}
		}
	}

	&-blue {
		.overlay {
			background-color: rgba(43, 57, 104, 0.8);

			&-solid {
				background-color: $blue;
			}
		}

		.accordions-title {
			color: $white;
		}

		.accordions-description {
			color: $white;
		}

		.accordion {
			.card {
				border-color: $blue;

				&:first-of-type {
					border-bottom: 1px solid $blue;
				}

				&-header {

					&:after {
						color: $blue;
					}

					&.collapsed {
						&:after {
							color: $blue;
						}
					}
				}

				&-title {
					color: $blue;

					&:hover {
						color: $blue;
					}
				}
			}
		}
	}
}
