[dir="rtl"] {
	header {
		.top-nav {
			.navbar {
				.nav-item{
					&:last-child {
						a {
						    padding-left: 0;
						}
					}
				}
			}
		}
	}

	.wsmenu02{
		 > .wsmenu02-list{
		 	text-align: left;
		 }
	}
}