﻿[dir="rtl"] {
	.boxouts {
		.boxout {

			&-align-center {
				.content {
					figure, h2, h3, p {
						text-align: center !important;
					}
				}
			}
		}
	}
}
