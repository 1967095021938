﻿[dir="rtl"] {
	.carousel {
		&-caption {
			text-align: right;
			transform: translate(0%, -50%);
		}

		&-control {
			&-prev-icon::after {
				content: '\f054';
			}

			&-next-icon::after {
				content: '\f053';
			}
		}
	}
}
