﻿.hero {
	.container {
		.profile-pic {
			img {
				height: 100%;
				width: 100%;
			}
		}
	}
}
