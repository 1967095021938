.serviceAZ {
	background-repeat: no-repeat;
	background-position: right bottom;
	position: relative;
	background-image: url(/dist/assets/img/rectangle_triangles.png);
	background-blend-mode: color-burn;

	h4 {
		color: $blue;
		font-size: 24px;
		line-height: 29px;
		font-weight: 700;
		border-bottom: 2px solid $dark-grey;
	}

	ul {
		padding-left: 0;
		list-style: none;

		li {
			line-height: 39px;
			font-size: 18px;
			border-bottom: 1px dashed $dark-grey;

			a {
				text-decoration: none;
				color: $black;
			}
		}
	}

	&-accordions {

		.accordion {
			.card {
				border-width: 0;
				background-color: transparent;

				&:not(:first-of-type):not(:last-of-type) {
					border-width: 0;
				}

				&-header {
					background-color: transparent;
					border-width: 0;
				}

				&-title {
					text-decoration: none;
					padding: 0 2.5rem 0 0;
					color: $blue;
					font-size: 24px;
					border-width: 0;
					border-bottom: 2px solid $dark-grey;

					&:after {
						top: 3px;
					}
				}

				&-body {
					padding: 0;

					ul {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}
