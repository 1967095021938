.hero {
	height: 370px;
	margin-bottom: 170px;
	background-image: initial;

	.row {
		padding-top: 140px;
	}

	.image {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 0;
	}

	.square {
		width: 100%;
		height: 0;
		padding-bottom: 100%;
		transform: translateY(-200%);

		.content {
			padding: 20px;

			h1 {
				font-size: 2.5rem;
				line-height: 2.9rem;
			}

			p {
				font-size: 0.9rem;
				line-height: 1.2rem;
			}
		}
	}
}

.slide-down {
	-webkit-animation-name: slide-down;
	-webkit-animation-duration: 1s;
	-webkit-animation-timing-function: ease;
	-webkit-animation-delay: 5s;
	-webkit-animation-fill-mode: forwards;
	animation-name: slide-down;
	animation-duration: 1s;
	animation-timing-function: ease;
	animation-delay: 0.5s;
	animation-fill-mode: forwards;
	transform: translateY(-200%);
}

