.carousel {

	&-caption {

		&-fluid {
			width: 880px;
		}
	}

	&-control {
		&-prev, &-next {
			width: 7%;
		}

		@media (min-width: 1200px) {
			//xl
			&-prev, &-next {
				width: 20%;
			}
		}
	}
}
