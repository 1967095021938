﻿.testimonials {
	.boxout {
		.content {
			.quote {
				p {
					font-size: 1.7rem;
					line-height: 40px;
				}
			}
		}
	}
}
