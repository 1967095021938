.news-menu {
    background   : #2F817A;
    padding      : 50px 0;
    margin-bottom: 15px;

    &__title {
        color      : #fff;
        font-size  : 55px;
        font-weight: 300;
        margin     : 0;
    }

    &__nav {
        align-self: center;
        flex      : 1;
        display   : none;

        @media (min-width: 992px) {
            display: flex;

        }
    }

    &__select {
        width   : 100%;
        position: relative;

        @media (min-width: 992px) {
            display: none;

        }

        select {

            color             : #fff;
            -webkit-appearance: none;
            -moz-appearance   : none;
            appearance        : none;
            background        : rgba(255, 255, 255, 0.2);
            border            : none;
            width             : 100%;
            border-radius     : 5px;
            padding           : 10px 40px 10px 10px;
            margin-top        : 20px;


        }

        i {


            position : absolute;
            bottom   : 11px;
            color    : #fff;
            right    : 20px;
            font-size: 20px;
        }

        option {
            color: #000
        }

    }

    &__link {
        a {
            font-size  : 24px;
            font-weight: 300;
            color      : #fff;

        }

        &--active {
            a {
                font-weight: 600;
                position   : relative;



                &::after {
                    content    : "\f0dd";
                    font-family: "Font Awesome 5 Pro";
                    position   : absolute;
                    bottom     : -50px;
                    right      : 50%;
                    transform  : translateX(50%);
                }
            }
        }

    }
}



.breadcrumb--news {
    margin-bottom: 30px;

    .breadcrumb {
        margin: 0 !important;


    }

    .breadcrumb-item {
        font-size   : 12px;
        color       : #2B3968;
        padding-left: 5px;

        a {
            color: #2F817A;
        }
    }

    .breadcrumb-item+.breadcrumb-item::before {
        content      : ".";
        padding-right: 5px;
    }
}

.generic-grid {
    margin-bottom: 40px;
    position     : relative;

    &__title {
        font-size    : 24px;
        margin-bottom: 20px;
        font-weight  : 400;
    }



    p {
        color: #909194;
    }

    &__media {
        position      : relative;
        width         : 100%;
        height        : 0;
        padding-bottom: 56%;
        margin-bottom : 20px;

        img {
            position  : absolute;
            width     : 100%;
            height    : 100%;
            left      : 0;
            top       : 0;
            object-fit: cover;
        }






    }

    &__type {
        position  : absolute;
        bottom    : 0;
        right     : 0;
        font-size : 11px;
        background: #2F817A;
        display   : inline-block;
        color     : #fff;
        padding   : 5px 10px;



    }

    &__info {
        color        : $blue;
        font-size    : 12px;
        font-weight  : 600;
        margin-bottom: 10px;

        svg {
            margin-right: 10px;
            width       : 20px;
        }
    }
}

.section-events {
    &__title {
        font-size    : 36px;
        margin-bottom: 40px;
    }
}


.news-generic-view {
    margin  : 20px 0 0;
    position: relative;

    text-align: center;

    @media (min-width: 767px) {
        text-align: left;
        margin    : 20px 0 40px;
    }

    &::after {
        position  : absolute;
        width     : 100%;
        height    : 1px;
        background: #707070;
        content   : "";
        right     : 0;
        top       : 50%;
        margin-top: -0.5px;
    }

    &__cont {
        background: #fff;
        padding   : 0 20px;
        position  : relative;
        z-index   : 1;
        display   : inline-block;


        @media (min-width: 767px) {
            padding-left : 0;
            padding-right: 40px;

        }
    }
}

.news-generic-title {
    font-size    : 36px;
    margin-bottom: 1em;
    font-weight  : 400;
}

.no-padding {
    padding: 0;
}

.section-events .btn {
    font-weight: 400;
}

.focus-grid {
    position: relative;

    &__media {

        @extend .generic-grid__media;
        padding-bottom: 156.2%;
    }

    &__copy {
        position: absolute;
        bottom  : 20px;
        left    : 0;
        width   : 100%;
        color   : #fff;


        z-index: 1;
        padding: 40px;
    }

    &__title {
        font-size  : 50px;
        font-weight: bold;
        color      : #fff;
    }

    &:after {
        width     : 100%;
        height    : 100%;
        position  : absolute;
        left      : 0;
        top       : 0;
        content   : "";
        background: linear-gradient(#2b3968 0%, rgba(22, 29, 52, 0) 100%);
        opacity   : 0.75;

    }

    &--detailed {
        .focus-grid {
            &__copy {
                color     : #909194;
                background: #fff;
                padding   : 10px;
                bottom    : 0;
            }

            &__title {
                color      : $blue;
                font-size  : 24px;
                font-weight: 400;
            }
        }
    }
}

.newletter-events {
    &__input {
        border       : 1px solid $blue;
        border-radius: 5px;
        font-size    : 20px;
        font-weight  : 400;
        max-width    : 369px;
        height       : 40px;
        width        : 100%;
        padding      : 0 10px;
    }

    .btn {
        padding: 6px 40px 8px 40px
    }

    &__cont {
        display  : flex;
        flex-wrap: wrap;
        gap      : 30px;
    }
}

.upcoming-events {
    background: #F3F3F3;
    padding   : 50px 0;

    @media (min-width: 767px) {
        padding: 50px 0 50px;
    }

    .news-generic-view__cont {
        background: #F3F3F3;
    }

    .generic-grid {
        background   : #fff;
        height       : calc(100% - 40px);
        padding      : 30px 20px;
        margin-bottom: 40px;
    }

    .swiper-slide {
        height: auto;
    }

    // .news-menu__select{
    //     


    // }
}

.light-bg {
    background: #F3F3F3;
}

.events-banner{
    img{
        width: 100%;
    }
}

.events-gallery{
    img{
        width: 100%;
    }
    p   {
        margin-top: 25px;
        font-size: 16px;
        color: #909194


    }

}

.events-filter {
    @extend .news-menu__select;
    height       : 44px;
    display      : block;
    float        : right;
    max-width    : 100%;
    margin-bottom: 20px;


    &-cont {
        display: block;
        gap    : 20px;

        @media (min-width: 767px) {
            display: flex;
        }
    }

    select {
        background: #6CA7A2;
        margin-top: 0;

        option {
            background: #fff;
        }
    }

    @media (min-width: 992px) {
        max-width: 370px;
    }
}

.events-content {
    &__info {
        display: flex;
        margin : 20px 0 0;
        gap    : 20px;
    }

    h2 {
        font-size  : 36px;
        font-weight: 400;
    }

    h4{
        font-size  : 20px;
        font-weight: 400;
    }

    h3 {
        font-size  : 30px;
        font-weight: 400;
    }

    p,
    li,
    span,
    b,
    strong {
        color: #909194;
    }

    .generic-grid__info {
        margin-bottom: 20px;
    }
}

.events-map {
    &__copy {
        background: $green;
        color     : #fff;
        padding   : 30px;
        height    : 100%;

        text-align: center;

        @media (min-width: 992px) {
            text-align: left;

        }

        h2,
        h3 {
            color: #fff;
        }

        h2 {
            font-size  : 24px;
            font-weight: 400;
        }

        h3 {
            font-size  : 16px;
            font-weight: 400;
        }

        p {
            font-size  : 14px;
            font-weight: 400;
        }
    }

    &__map {
        height: 300px;
        margin-top: 20px;

        @media (min-width: 992px) {
            height: 100%;
            margin-top: 0;

        }

        iframe {
            width : 100%;
            height: 100%;
        }
    }

    &__cta {
        display   : flex;
        flex-flow : column;
        gap       : 20px;
        margin-top: 60px;

        align-items: center;
        @media (min-width: 992px){
            align-items: normal;
        }

        .btn {
            max-width: 240px;

            svg {
                margin-right: 10px;
            }
        }
    }
}


.events-interest {
    background: $green;
    padding   : 50px 0;

    h2 {
        font-size  : 36px;
        font-weight: 400;
        color      : $white;
    }

    label {
        color: $white;
    }

    .btn {
        margin-top: 40px;
    }

    form {
        margin-top: 40px;
    }
}