.find-doctor {
	.container {
		form {
			select, input {
				color: #4A4A4A;
				border-radius: 0;
			}

			.btn {

				&.small {
					font-size: 18px;
					line-height: 22px;
				}
			}
		}
	}
}

.profiles-archive {

	.profile {
		h3 {
			font-size: 24px;
			line-height: 29px;
		}

		p {
			font-size: 16px;
			line-height: 22px;
		}

		.link {
			margin-top: 1rem;
			display: block;
			color: $green;
			font-weight: 700;
		}
	}
}
