html {
	font-size: 16px;
}

body {
	font-family: 'Lato', sans-serif;
}

.clearfix:before,
.clearfix:after {
	content: "";
	display: table;
}

.clearfix:after {
	clear: both;
}

h1, h2, h3, h4, h5, h6 {
	font-family: 'Lato', sans-serif;
	font-weight: 700;
	color: $blue;
}

ol ol ul, ol ul ul, ul ol ul, ul ul ul {
	list-style-type: none;
	padding-left: 0;
}

a {
	color: $green;

	&:hover {
		color: $green;
	}
}

textarea, select, input, button {
	outline: none;
}

:focus {
	outline-color: transparent;
	outline-style: none;
}

.wrapper {
	overflow: hidden;
}

.d-xs-block {
	display: block !important;
}

section {
	margin-bottom: 50px;

	//please note: these are capilised as the properties are coming from umbraco
	&.Gutter, &.gutter {
		margin-bottom: 0;
	}

	&.Minimum, &.minimum {
		margin-bottom: 3px;
	}

	&.None, &.none {
		margin-bottom: 0;
	}
}

button {
	/*border: 0;
	border-radius: 50px;
	padding: 10px 40px;
	font-size: 14px;
	font-weight: 500;*/
}

.blue {
	background-color: $blue;
}

.green {
	background-color: $green;
}

.grey {
	background-color: $grey;
}

.white {
	background-color: $white;
}

.blue_text {
	color: $blue;
}

.green_text {
	color: $green;
}

.white_text {
	color: $white;
}

.black_text {
	color: $black;
}

.displaynone {
	display: none;
}


//todo: check these with tom - should they be global or in boxouts?
.green {
	h1, h2, h3, p, .category, .link, a, li {
		color: $white;
	}

	a {
		text-decoration: underline;
	}

	.category, .link {
		text-decoration: none;
	}

	button {
		color: $green;
		background-color: $white;
	}
}

.blue {
	h1, h2, h3, p, .category, .link, a li {
		color: $white;
	}

	a {
		text-decoration: underline;
	}

	.category, .link {
		text-decoration: none;
	}

	button {
		color: $blue;
		background-color: $white;
	}
}

.grey {
	h1, h2, h3, p, .category, .link, a, li {
		color: $blue !important;
	}

	a {
		text-decoration: underline;
	}

	.category, .link {
		text-decoration: none;
	}

	button {
		color: $grey;
		background-color: $blue;
	}
}


.mychart-login {
	display: none;
}

[dir="rtl"] {
	direction: rtl;


	.row [class*="col-"] {
		float: right;
	}
}

::selection, ::-moz-selection {
	color: $white;
	background: $green;
}

.rtl {
	direction: rtl;
	text-align: right;
	unicode-bidi: bidi-override;
	font-family: 'Lato', sans-serif;
}

.ltr {
	direction: ltr;
	text-align: left;
	unicode-bidi: bidi-override;
	font-family: 'Noto Kufi Arabic', 'Lato', sans-serif;
}

.grecaptcha-badge {
	z-index: 99999 !important;
	display: block !important;
}