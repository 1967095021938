body {
	&.debug {
		&:before {
			background: #f8d928;
			content: "xs";
			color: #fff;
			font-family: Verdana;
			font-size: 9px;
			left: 0;
			line-height: 9px;
			margin: 0;
			padding: 2px 3px;
			position: fixed;
			text-transform: uppercase;
			top: 0;
			z-index: 10003;
			// Small devices (landscape phones, 576px and up)
			@media (min-width: 576px) {
				background: #f26522;
				content: "sm";
			}
			// Medium devices (tablets, 768px and up)
			@media (min-width: 768px) {
				background: #8dc63f;
				content: "md";
			}
			// Large devices (desktops, 992px and up)
			@media (min-width: 992px) {
				background: #4caeeb;
				content: "lg";
			}
			// Extra large devices (large desktops, 1200px and up)
			@media (min-width: 1200px) {
				background: #662d91;
				content: "xl";
			}
		}
	}
}


/*.container, .container-fluid {
	background-color: rgba(0, 0, 0, 0.1);
}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
	background-color: rgba(48, 129, 122, 0.1);
}*/