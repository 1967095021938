﻿.boxout {
	&-green {
		background-color: $green;

		.category {
			text-decoration: none;
			color: $white;

			&:hover {
				color: $white;
				text-decoration: none;
			}
		}

		.content {
			h2, h3, h4, h5, p, a, a:hover, ul, ol {
				color: $white !important;
			}

			.cta .primary-link {
				color: $green !important;

				&:hover {
					color: $green !important;
				}
			}

			.secondary-link {
				text-decoration: none;
				color: $white;

				&:hover {
					color: $white;
					text-decoration: none;
				}
			}
		}
	}

	&-blue {
		background-color: $blue;

		.category {
			text-decoration: none;
			color: $white;

			&:hover {
				color: $white;
				text-decoration: none;
			}
		}

		.content {
			h2, h3, h4, h5, p, a, a:hover, ul, ol {
				color: $white !important;
			}

			.cta .primary-link {
				color: $blue !important;

				&:hover {
					color: $blue !important;
				}
			}

			.secondary-link {
				text-decoration: none;
				color: $blue;

				&:hover {
					color: $blue;
					text-decoration: none;
				}
			}
		}
	}

	&-grey {
		background-color: $grey;

		.category {
			text-decoration: none;
			color: $blue;

			&:hover {
				color: $blue;
				text-decoration: none;
			}
		}

		.content {
			h2, h3, h4, h5, p, a, a:hover, ul, ol {
				color: $blue !important;
			}

			.cta .primary-link {
				color: $blue !important;

				&:hover {
					color: $blue !important;
				}
			}

			.secondary-link {
				text-decoration: none;
				color: $blue;

				&:hover {
					color: $blue;
					text-decoration: none;
				}
			}
		}
	}

	&-white {
		background-color: $white;
		border: 1px solid $blue !important;

		.content {
			h2, h3, h4, h5 {
				color: $blue !important;
			}

			p, a, a:hover, ul, ol {
				color: $black !important;
			}
		}
	}
}
