﻿.image-actions {

	.boxout {
		position: relative;
		padding-bottom: 100%;
		overflow: hidden;
		height: 100%;

		&-twitter {
			height: 100% !important;

			iframe {
				height: 100% !important;
			}
		}

		&-rectangle {
			padding-bottom: 50% !important;

			.content {
				height: 100% !important;
			}
		}

		&-half-half {
			padding-bottom: 0 !important;
		}

		.content {
			position: absolute;
			height: 100%;
			padding: 15px 15px 0 15px;

			&-half-half {
				position: relative;
			}
		}
	}

	.boxout-icon {
		height: 100%;

		.content {
			text-align: left;
			left: 0;

			figure {
				margin-left: 0;
				display: inline-block;
				float: none;
				min-height: auto;

				img {
					max-height: 95px;
				}
			}
		}
	}
}
