﻿[dir="rtl"] {
	footer {
		.social {
			text-align: right;

			&-icons a {
				margin-right: 0px;
			}
		}

		.mr-auto {
			margin-right: 0 !important;
		}

		.footer-copyright {
			.container {
				ul {
					text-align: left;
				}
			}
		}
	}
}
