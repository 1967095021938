.mychart-login {
	display: block;
	position: fixed;
	right: 0;
	top: 30%;
	z-index: 9999;
}

section {

	//please note: these are capilised as the properties are coming from umbraco
	&.Gutter, &.gutter {
		margin-bottom: 30px;
	}
}
