﻿.three-icon-actions {

	.boxout {
		height: 100%;

		.content {
			text-align: center;
			left: 0;

			figure {
				margin-left: 0;
				display: inline-block;
				float: none;
				min-height: auto;
			}
		}
	}
}
