﻿/* please note: this module originally only allowed 3 items and was on a green background...
 * we decided to allow 4 items and introduce a colour picker...
 * however renaming the doctype alias in umbraco resulted in lost data so we decided to leave the alias and file names as is
 */
.three-icon-actions-buttons {

	.content {
		height: 100%;
		padding: 10px;

		img {
			width: 86px;
		}

		p {
		}


		&-green {

			.content {

				img {
					margin-top: 45px;
				}

				p {
					padding-bottom: 45px;
				}
			}
		}
	}
}
