.carousel {

	.container-fluid {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}

	&-item {
		height: 470px;
		background-color: $grey;
		background-size: cover;
		background-position: center;
	}

	&-caption {
		top: 50%;
		left: 50%;
		z-index: 10;
		color: #ffffff;
		text-align: left;
		padding: 0;
		height: 350px;
		transform: translate(-50%, -50%);
		width: 70%;

		&-inner {
			width: 100%;
			height: 350px;
			background: rgba(80, 80, 80, 0.75);
			padding: 20px 15px 15px 15px;

			h2 {
				color: #ffffff;
			}

			a {
				text-decoration: underline;
				color: #ffffff;

				&:hover {
					color: #ffffff;
				}
			}

			&-link {
				position: absolute;
				bottom: 10px;
				text-decoration: none !important;
			}
		}
	}

	&-control {
		&-prev, &-next {
			width: 7%;
			opacity: 0.7;
		}

		&-prev-icon, &-next-icon {
			width: 50px;
			height: 50px;
			background-image: none;
		}

		&-prev-icon::after, &-next-icon::after {
			font-family: 'Font Awesome 5 Pro';
			font-size: 33px;
			font-style: normal;
			font-weight: 400;
			display: block;
		}

		&-prev-icon::after {
			content: '\f053';
		}

		&-next-icon::after {
			content: '\f054';
		}
	}

	&-indicators li {
		width: 10px;
		height: 10px;
		border-radius: 100%;
		cursor: pointer;
	}
}
