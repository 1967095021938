﻿.testimonials {

	.row {
		&-testimonials {
			margin-top: 100px;
		}
	}

	.boxout {
		float: left;
		position: relative;
		width: 100%;
		overflow: hidden;
		margin: 0;
		padding-bottom: 0;
		overflow: visible;
		height: 100%;
		border: 1px solid $grey;

		.content {
			padding: 100px 5% 0 5%;
			position: relative;
			text-align: center;

			p {
				color: $blue;

				&.link {
					font-weight: 700;
				}
			}

			figure {
				width: 126px;
				margin: 0 auto;
				text-align: center;
				font-size: 56px;
				position: absolute;
				left: 0;
				right: 0;
				background: $blue;
				color: $white;
				border-radius: 100px;
				padding: 20px;
				top: -56px;
			}

			.quote {
				&:before, &:after {
					font-family: 'Font Awesome 5 Pro';
					font-weight: 900;
					color: $blue;
					font-size: 26px;
					line-height: 42px;
				}

				&:before {
					content: "\f10d";
				}

				&:after {
					content: "\f10e";
				}

				p {
					color: $black;
					font-size: 1.4rem;
					line-height: 1.8rem;
					font-style: italic;
				}
			}
		}
	}
}
