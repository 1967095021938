﻿.campaign-pull-quote {
	background-repeat: no-repeat;
	background-position: right center;
	text-align: center;

	&.default-background-image {
		background-image: url(/dist/assets/img/square_triangles.png);
	}

	.content {
		margin-bottom: 50px;

		h2 {
			font-weight: 400;
			margin-top: 30px;
			margin-bottom: 40px;
		}

		.quote {
			padding: 20px 30px 20px 30px;
			margin-bottom: 30px;
			font-style: italic;
			font-size: 1.3rem;
		}

		.quote::before,
		.quote::after {
			font-family: 'Font Awesome 5 Pro';
			font-weight: 900;
			font-size: 30px;
			font-style: normal;
			display: block;
		}

		.quote::before {
			content: '\f10d';
			margin-bottom: 10px;
		}

		.quote::after {
			content: '\f10e';
			margin-top: 10px;
		}
	}
}
