footer {
	.footer-logo {
		img {
			width: auto;
			height: 85px;
		}
	}

	background-color: $blue;

	> .container {
		padding-bottom: 15px;
	}

	a {
		color: $white;

		&:hover {
			color: $white;
		}
	}

	p {
		color: $white;
	}

	#footer-menu {
		.list-unstyled {
			li {
				p {
					padding-bottom: -1px;
					margin-bottom: 13px;
				}
			}
		}
	}

	.footer-copyright {
		background-color: $dark-blue;
		color: $white;

		.container {
			p, a {
				color: $white;
				font-size: 1rem;
			}

			.legal-info-links {

				> li + li:before {
					display: inline-block;
					padding-right: 0.5rem;
					padding-left: 0;
					color: $white;
					content: "|";
				}
			}
		}
	}

	.social {
		p {
			margin: 10px 0 5px 0;
		}

		&-icon {
			width: 36px;
		}
	}

	.signup-form {
		padding: 10px 10px 4px 10px;
		border: 1px solid $white;

		h5 {
			font-weight: bold;
			color: #fff;
			margin-top: -23px;
			background: $blue;
		}

		p {
			font-size: 0.9rem;
			line-height: 1.2rem;
		}

		form {
			.input-group-append {
				width: 100%;
				margin-left: 0;
			}

			.text {
				border-radius: 5px !important;
				border: 0;
				margin: 0;
				width: 100%;
			}

			.btn {
				border-radius: 0.3rem !important;
				width: 100%;
			}

			.label {
				color: $white;
				height: 10px;
				font-size: 0.7rem;
			}
		}
	}
}
