﻿.header-block-overlay {
	background-size: cover;
	background-position: center;
	position: relative;

	.row {
		background-color: rgba(48, 129, 122, 0.95);
	}

	.content {
		width: 100%;
		min-height: 300px;
		padding: 5%;
		color: $white;

		h1 {
			font-size: 2.5rem;
			line-height: 2.9rem;
			font-weight: 100;
			color: $white;
		}

		.cta {
			text-align: center;
			margin-top: 20%;

			.primary-link {
				margin: 0 0 20px 0;
			}

			.secondary-link {
				margin: 0 0 20px 0;
			}
		}
	}
}
