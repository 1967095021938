﻿.three-image-overlay-actions {

	.boxout {
		position: relative;
		width: 100%;
		height: 100%;
		padding-bottom: initial;
		background-size: cover;
		background-position: center;

		.content {
			position: relative;
			width: 100%;
			height: 100%;
			padding: 15px 15px 0 15px;
			bottom: 0;

			h2 {
				color: $white;
				font-weight: 700;
			}

			p {
				color: $white;
				font-weight: 400;
			}

			a.link {
				color: $white;
			}
		}
	}
}
