.campaign-header-block {
	background-repeat: no-repeat;
	background-position: right center;
	position: relative;

	&.default-background-image {
		background-image: url(/dist/assets/img/rectangle_triangles.png);
		background-blend-mode: color-burn;
	}

	.content {
		margin-bottom: 50px;

		h2 {
			font-weight: 100;
			font-size: 1.8rem;
			margin-top: 50px;
		}

		h3 {
			font-size: 1.3rem;
			font-weight: 700;
		}
	}
}
