﻿.three-image-overlay-actions {
	.boxout {
		padding-bottom: 100%;
		overflow: hidden;
		height: 100%;

		.content {
			position: absolute;
			height: 100%;
			padding: 20px 20px 0 20px;

			p {
				&.read-more {
					position: absolute;
					bottom: 0;
				}
			}
		}
	}
}
