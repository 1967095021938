﻿.quick-links {
	background-color: $green;
	color: $white;
	padding: 40px 0 40px 0;

	h2 {
		color: $white;
	}

	.items {
		p {
			font-style: italic;

			a {
				font-style: normal;
			}
		}

		.two:not(:first-child) {
			border-left: 1px solid $green;
			border-right: 1px solid $green;
		}

		.three:not(:first-child) {
			border-left: 1px solid $green;
			border-right: 1px solid $green;
		}
	}
}
