footer {
	.footer-logo {
		width: 50%;
		float: left;
	}

	.social {
		width: 50%;
		float: right;
		text-align: right;
	}

	.signup-form {
		form {

			.input-group-append {
				width: auto;
				margin-left: -1px;
			}

			.text {
				width: 1%;
				margin: 0 7px 0 0;
			}

			.label {
				width: 100%;
			}
		}
	}
}
