﻿[dir="rtl"] {
	footer {
		form {
			.input-group-append {
				margin-right: 0;
			}
		}

		.footer-copyright {

			.container {
				text-align: right;

				ul {
					text-align: right;
				}

				.legal-info-links {

					> li + li:before {
						display: inline-block;
						padding-left: 1rem;
						padding-right: 0;
						color: $white;
						content: "|";
					}
				}
			}
		}
	}
}
