.hero {
	margin-bottom: 30px;
	height: auto;
	position: relative;

	//this is a bit of a *hack* as the 'style.scss' uses min width (we'd need to reinstate the boostrap css as we went up the breakpoints where as this overrides them)
	@media (max-width: 992px) {
		.container {
			width: 100%;
			max-width: none;
			padding-left: 0;
			padding-right: 0;
		}

		.col-12, .col-md-12 {
			padding-left: 0;
			padding-right: 0;
		}

		.row {
			padding-top: 0;
			padding-left: 15px;
			padding-right: 15px;
		}

		.breadcrumb {
			padding: 5px 20px 5px 20px;
		}
	}

	.image {
		background-size: cover;
		background-position: center;
		height: 150px;
	}

	.square {
		background-color: $green;

		.content {
			padding: 20px;

			h1 {
				color: $white;
				font-weight: 100;
				font-size: 2.5rem;
				line-height: 2.9rem;
			}

			p {
				color: $white;
				font-weight: 400;
				font-size: 1rem;
				line-height: 1.2rem;
				margin-bottom: 0;
			}
		}
	}
}
