﻿[dir="rtl"] {
	.quick-links {
		.items {
			.two:not(:first-child) {
				border-left: 1px solid $green;
				border-right: 1px solid $white;
			}
		}
	}
}
