﻿//override for umbraco forms where a hidden field is output
.form-group.hidden {
	margin-bottom: 0 !important;
}

.forms {
	padding: 40px 0 40px 0;

	&-white {
		background-color: $white;

		legend, label {
			color: $blue !important;
		}

		.field-validation-error {
			color: #dc3545;
		}

		.input-validation-error {
			border-color: #dc3545;
		}
	}

	&-green {
		background-color: $green;

		h2, h3, h4, h5, p, a, a:hover, legend, label, .form-text {
			color: $white !important;
		}

		.field-validation-error {
			color: #ff9900;
		}

		.input-validation-error {
			border-color: #ff9900;
		}
	}

	&-grey {
		background-color: $grey;

		h2, h3, h4, h5, p, a, a:hover, legend, label, .form-text {
			color: $blue !important;
		}

		.field-validation-error {
			color: #dc3545;
		}

		.input-validation-error {
			border-color: #dc3545;
		}
	}

	&-blue {
		background-color: $blue;

		h2, h3, h4, h5, p, a, a:hover, legend, label, .form-text {
			color: $white !important;
		}

		.field-validation-error {
			color: #dc3545;
		}

		.field-validation-success {
			color: $white;
		}

		.input-validation-error {
			border-color: #dc3545;
		}
	}
}
