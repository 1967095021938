﻿.boxouts {

	&-three {
		.boxout {
			.content {
				padding: 4%;
			}
		}
	}
}
