.order-sm-1 {
    -ms-flex-order: 6;
    order: 6;
}
.order-sm-6 {
    -ms-flex-order: 3;
    -order: 3;
    order: 3;
}
.order-sm-3 {
    -ms-flex-order: 1;
    order: 1;
}