[dir="rtl"] {
	header {
		.top-nav {

			.nav-item {

				.nav-link.dropdown:after {
					margin-left: auto;
					margin-right: .255em;
				}
			}
		}
	}
}
