﻿.loading {
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;

    &-fade {
        background: rgba(0, 0, 0, 0.5);
        height: 100%;
        left: 0;
        margin: 0;
        padding: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 9990;
    }

    &-spinner {
        height: 40px;
        left: 50%;
        margin: 0;
        padding: 0;
        position: fixed;
        top: 50%;
        width: 40px;
        z-index: 9998;
        color: $white;
    }
}