﻿.campaign-pull-quote {
	.content {
		margin-bottom: 50px;

		h2 {
			margin-top: 80px;
			margin-bottom: 50px;
		}

		.quote {
			padding: 20px 30px 20px 30px;
		}
	}
}