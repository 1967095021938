﻿.my-chart-action {
	background-color: $green;

	a, a:hover {
		text-decoration: none;
	}

	.content {
		color: $white;

		a, a:hover {
			color: $white;
			text-decoration: none;
		}

		h2 {
			font-size: 22px;
			color: $white;

			.details {
				font-weight: 400;
			}

			.mychart-icon {
				height: 30px;
			}
		}
	}
}
