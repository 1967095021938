header {
	.top-nav {
		.navbar {
			padding: 0.5rem 1rem;

			.search {
				.input-group {
					margin-top: 4px;
				}
			}

			.dropdown:hover > .dropdown-menu {
				//this makes the flyout on hover rather than on click
				display: block;
			}

			.dropdown > .dropdown-toggle:active {
				//without this, clicking will make it sticky
				pointer-events: none;
			}

			.nav-item {
				font-size: 0.9rem;

				.nav-link.dropdown:after {
					display: inline-block;
					width: 0;
					height: 0;
					margin-left: .255em;
					vertical-align: .255em;
					content: "";
					border-top: .3em solid;
					border-right: .3em solid transparent;
					border-bottom: 0;
					border-left: .3em solid transparent;
				}

				.dropdown-menu {
					background-color: $blue;
					top: 90% !important;
					border: 0;

					.dropdown-item {
						font-size: 0.9rem;
						color: $white;

						&:hover {
							background-color: $blue;
						}
					}
				}
			}
		}
	}

	.main-logo {
		padding: 25px 0 20px 0;

		a {
			margin: 0;
		}
	}
}
