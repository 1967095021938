﻿.location-details {
	.opening-hours {

		.title-and-status {

			p {
				margin: 7px 0 0 0;
			}
		}
	}

	.map {
		iframe {
			height: 100% !important;
		}
	}


	&-tabbed-content {
		.tab-content {

			.panel-collapse {

				&.collapse {
					border-top: none !important;
				}
			}
		}
	}
}
