﻿[dir="rtl"] {
	.accordions {
		.accordion {
			.card {
				&-title {
					padding: .75rem .75rem .75rem 2.5rem;

					&:after {
						right: auto;
						left: 13px;
					}
				}
			}
		}
	}
}
