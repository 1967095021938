﻿.timeline {
	.accordion {
		.item {
			&-header {
				margin: 0;
			}

			&-body {
				.marker:after {
					content: '';
					position: absolute;
					right: -33px;
					top: 49px;
					transform: translateX(-50%);
					width: 30px;
					height: 30px;
					border-radius: 50%;
					background-color: $green;
					display: block;
				}

				.left {
					&-border {
						border-left: 3px solid $green;
					}

					&-border-empty {
						border-left: 3px solid $green;
					}

					&-sub-item {
						margin: 20px 15px 0 0;

						&:after {
							left: 100%;
							top: 44px;
							border: solid transparent;
							content: " ";
							height: 0;
							width: 0;
							position: absolute;
							pointer-events: none;
							border-color: rgba(0, 0, 0, 0);
							border-left-color: #ffffff;
							border-width: 10px;
							margin-top: -10px;
						}
					}
				}

				.right {
					&-border {
						border-right: 3px solid $green;
					}

					&-sub-item {
						margin: 20px 0 0 15px;

						&:after {
							right: 100%;
							top: 44px;
							border: solid transparent;
							content: " ";
							height: 0;
							width: 0;
							position: absolute;
							pointer-events: none;
							border-color: rgba(0, 0, 0, 0);
							border-right-color: #ffffff;
							border-width: 10px;
							margin-top: -10px;
						}
					}
				}
			}
		}

		.item-spacer {
			display: block;
		}
	}
}