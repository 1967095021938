.timeline {
	background-image: url(../assets/img/timeline.png);
	background-size: cover;
	background-position: bottom;
	background-color: #E6F0F0;
	padding-top: 50px;
	padding-bottom: 50px;

	.rich-text {

		p {
			font-style: italic;
		}
	}

	.accordion {
		.item {
			background-color: transparent;
			border: none;

			&-header {
				background-color: transparent;
				border: none;
				margin: 0 0 20px 0;
				padding: 0;

				&-title-circle {
					width: 100px;
					height: 100px;
					line-height: 100px;
					border-radius: 50%;
					text-align: center;
					margin: 0 auto;
					background-color: $green;
				}
			}

			&-title {
				color: $white !important;
				width: 100px;
				height: 100px;
				display: inline-block;

				&:hover {
					text-decoration: none;
				}
			}

			&-body {
				margin: 0;
				padding: 0;

				.marker::after {
					display: none;
				}

				.left {
					&-border {
						border-left: none;
						padding-bottom: 20px;
					}

					&-sub-item {
						position: relative;
						background-color: #ffffff;
						border-radius: 5px;
						padding: 10px 10px 1px 10px;
						margin: 0;
						border: 1px solid #ffffff;
					}
				}

				.right {
					&-border {
						border-right: none;
						padding-bottom: 20px;
					}

					&-sub-item {
						position: relative;
						background-color: #ffffff;
						border-radius: 5px;
						padding: 10px 10px 1px 10px;
						margin: 0;
						border: 1px solid #ffffff;
					}
				}

				time {
					display: block;
					font-size: 1.2rem;
					font-weight: bold;
					margin-bottom: 8px;
				}
			}
		}

		.item-spacer {
			display: none;
		}
	}
}
