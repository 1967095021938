.rich-text {
	position: relative;
	z-index: 40;

	h {
		&1, &2, &3, &2 a, &3 a {
			color: $blue;
		}

		&1 {
			font-size: 2.5rem;
			line-height: 1.2;
			font-weight: 700;
		}

		&2 {
			font-size: 1.8rem;
			line-height: 1.4;
			font-weight: 700;
		}

		&3 {
			font-size: 1.2rem;
			line-height: 1.4;
			font-weight: 700;
		}
	}

	p {
		a {
			color: $green;
			text-decoration: underline;
		}
	}

	ul {
		list-style-type: disc;
		font-weight: 400;

		ul {
			list-style-type: circle;
		}
	}

	ol {
		font-weight: 400;
	}

	&-green, &-grey, &-blue {
		padding: 40px 0 40px 0;
	}

	&-green {
		background-color: $green;

		h {
			&1, &2, &3, &2 a, &3 a {
				color: $white !important;
			}
		}

		p, a, a:hover, li, th, td {
			color: $white !important;
		}
	}

	&-grey {
		background-color: $grey;

		h {
			&1, &2, &3, &2 a, &3 a {
				color: $blue !important;
			}
		}

		p, a, a:hover, li, th, td {
			color: $blue !important;
		}
	}

	&-blue {
		background-color: $blue;

		h {
			&1, &2, &3, &2 a, &3 a {
				color: $white !important;
			}
		}

		p, a, a:hover, li, th, td {
			color: $white !important;
		}
	}
}
