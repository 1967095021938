[dir="rtl"] {
	* {
		font-family: 'Noto Kufi Arabic', 'Lato', sans-serif;
	}

	.fab, .fas, .fa, .far, .fal {
		font-family: 'Font Awesome 5 Pro' !important;
	}

	.direction-ltr {
		direction: ltr;
		text-align: left;

		&[class*="col-"] {
			float: left !important;
		}
	}

	//no idea why but the bootstrap cdn for rtl (https://cdn.rtlcss.com/bootstrap/v4.0.0/css/bootstrap.css) doesn't have the borderless tables?!
	.table-borderless th,
	.table-borderless td,
	.table-borderless thead th,
	.table-borderless tbody + tbody {
		border: 0;
	}
}
