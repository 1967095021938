﻿[dir="rtl"] {
	footer {
		.signup-form {
			form {

				.input-group-append {
					margin-right: -1px;
				}

				.text {
					margin: 0 0 0 7px;
				}
			}
		}
	}
}
