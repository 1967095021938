﻿.pagination {
	.page-item {
		display: block !important;
	}

	.page-link {
		span.text {
			display: none;
		}
	}
}
