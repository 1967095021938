﻿[dir="rtl"] {
	.timeline {
		.accordion {
			.item {
				&-body {
					.marker::after {
						left: -3px;
						right: auto;
					}

					.left {
						&-border {
							border-left: 0;
							border-right: 3px solid $green;
						}

						&-border-empty {
							border-left: 0;
							border-right: 3px solid $green;
						}

						&-sub-item {
							margin: 20px 0 0 15px;

							&::after {
								right: 100%;
								left: auto;
								border-left: none;
								border-right-color: #ffffff;
							}
						}
					}

					.right {
						&-border {
							border-right: 0;
							border-left: 3px solid $green;
						}

						&-sub-item {
							margin: 20px 15px 0 0;

							&::after {
								right: auto;
								left: 100%;
								border-left-color: #ffffff;
								border-right: none;
							}
						}
					}
				}
			}
		}
	}
}
