@import "./_fonts";
@import "./_mixins";

@import "plugins/fontawesome/all";
@import "plugins/webslidemenu/main";
@import "plugins/selection-sharer";
@import "plugins/swiper";

@import "./_debug";
@import "./_screen_xs";

@media 
// only screen and 
(min-device-width: 375px) 
  and (max-device-width: 812px) 
  // and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) 
  { 
	@import "./_screen_landscape";
}

@media (min-width: 576px) {
	@import "./_screen_sm";
}

@media (min-width: 768px) {
	@import "./_screen_md";
}

@media (min-width: 992px) {
	@import "./_screen_lg";
}

@media (min-width: 1200px) {
	@import "./_screen_xlg";
}

// @media all and (max-width: 1199px) {  } 
// @media all and (max-width: 991px) {  } 
// @media all and (max-width: 768px) {  } 
// @media all and (max-width: 575px) { }
 