﻿.location-details {
	.only-desktop {
	  width: 100%;
	  display: flex;
	  .column-left {
		padding: 0 1.5% 0 0;
	  }
	  .column-right {
		padding: 0 0 0 1.5%;
	  }
	}
	.only-mobile {
	  display: none;
	}
	.hours-contenitor {
	  display: flex;
	  width: 100%;
	  flex-wrap: wrap;
  
	  .column-right,
	  .column-left {
		display: inline-flex;
		flex-direction: column;
		width: 50%;
		flex-wrap: wrap;
		align-items: flex-start;
	  }
	  .hours-wrap {
		display: block;
		width: 100%;
		box-sizing: border-box;
	  }
	}
  }
  