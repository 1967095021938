@keyframes slide-down {
	0% {
		transform: translateY(-200%);
	}

	100% {
		transform: translateY(0%);
	}
}

@-webkit-keyframes slide-down {
	0% {
		-webkit-transform: translateY(-200%);
	}

	100% {
		-webkit-transform: translateY(0%);
	}
}

// Colors
$black: #000000;
$white: #ffffff;
$green: #30817A;
$blue: #2B3968;
$dark-blue: darken($blue, 20%);
$light-grey: #F0F0F0;
$grey: darken($light-grey, 20%);
$dark-grey: darken($grey, 20%);
$night-main-colour: #000;
$spec-blue: #078EDA;

@mixin play-4 {
	font-family: 'Playfair Display', serif;
	font-weight: 400;
}

@mixin play-7 {
	font-family: 'Playfair Display', serif;
	font-weight: 700;
}

@mixin play-9 {
	font-family: 'Playfair Display', serif;
	font-weight: 900;
}

@mixin box-sizing($size: border-box) {
	-moz-box-sizing: $size;
	-webkit-box-sizing: $size;
	box-sizing: $size;
}
