﻿.info-block {
	background-color: $green;
	color: $white;
	padding: 40px 0 40px 0;

	h2 {
		color: $white;
	}

	h3 {
		color: $white;
	}

	a {
		color: $white;
		font-weight: 700;

		&:hover {
			text-decoration: none;
		}
	}

	.divider {
		border-top: 1px solid $white;
		padding: 30px 0 14px 0;

		&-first, &-first-row {
			border-top: 1px solid $white;
			padding: 30px 0 14px 0;
		}

		&-first {
			border-top: none;
		}
	}
}
