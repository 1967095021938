﻿.boxouts {
	.boxout {
		&-align-center {
			.content {
				figure, h2, h3, p {
					text-align: center !important;
				}
			}
		}
	}

	&-three {
		.boxout {
			.content {
				padding: 20% 5%;
			}
		}
	}

	&-two {
		.boxout {
			.content {
				padding: 10% 5%;
			}
		}
	}
}
