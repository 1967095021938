﻿.job-search-bar {
	background-repeat: no-repeat;
	background-position: right center;
	position: relative;
	background-image: url(/dist/assets/img/rectangle_triangles.png);
	background-blend-mode: color-burn;
	background-color: $blue;

	a, a:hover {
		text-decoration: none;
	}

	.content {
		color: $white;
		margin-bottom: 50px;

		h2 {
			margin-top: 50px;
			color: $white;
		}

		p {
			font-style: italic;
		}
	}
}
