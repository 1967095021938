﻿/*
	please note: this has been heavily customised from the original css!
	
	this is a copy/paste from the en version with overrides 

	i've left all the other styles in as it makes it a hellofalot easier to figure out what's controlling what in the css soup...
*/

[dir="rtl"] {
	/* ================== Desktop Base CSS  ================== */
	.wsmenu html, .wsmenu body, .wsmenu iframe, .wsmenu h1, .wsmenu h2, .wsmenu h3, .wsmenu h4, .wsmenu h5, .wsmenu h6 {
		/*margin: 0;*/
		/*padding: 0;*/
		/*border: 0;*/
		/*font: inherit;*/
		/*vertical-align: baseline;*/
		/*font-weight: normal;*/
		/*line-height: 18px;*/
		/*-webkit-font-smoothing: subpixel-antialiased;*/
		/*font-smoothing: antialiased;*/
		/*font-smooth: antialiased;*/
		/*-webkit-text-size-adjust: 100%;*/
		/*-ms-text-size-adjust: 100%;*/
		/*-webkit-font-smoothing: subpixel-antialiased;*/
		/*font-smoothing: subpixel-antialiased;*/
		/*font-smooth: subpixel-antialiased;*/
	}

	.wsmenu .cl {
		/*clear: both;*/
	}

	.wsmenu img, object, embed, video {
		/*border: 0 none;*/
		/*max-width: 100%;*/
	}

	.wsmenu a:focus {
		/*outline: none;*/
	}

	.wsmenu:before, .wsmenu:after {
		/*content: "";*/
		/*display: table;*/
	}

	.wsmenu:after {
		/*clear: both;*/
	}
	/* ================== Desktop Main Menu CSS ================== */
	.headerfull {
		/*width: 100%;*/
		/*height: 60px;*/
		/*position: relative;*/
		/*-webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);*/
		/*-moz-box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);*/
		/*box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);*/
	}

	.smllogo {
		/*padding: 9px 0px 0 16px;*/
		/*margin: 0;*/
		/*float: left;*/
		/*width: 6%;*/
	}

	.smllogo a {
		/*display: block;*/
		/*float: left;*/
		/*padding: 0;*/
	}

	.wsmain {
		/*width: 100%;*/
		/*margin: 0 auto;*/
		/*padding: 0 25px;*/
		/*max-width: 1300px;*/
	}

	.wsmenu {
		/*width: 100%;*/
		/*float: left;*/
		/*color: #fff;*/
		/*position: relative;*/
		/*font-size: 1rem;*/
		/*padding: 0;*/
		/*display: block;*/
		/*margin: 0;*/
	}

	.wsmenu > .wsmenu-list {
		text-align: right !important;
		/*margin: 0 auto;*/
		/*width: 100%;*/
		/*display: block;*/
		/*padding: 0;*/
		/*position: relative;*/
	}

	.wsmenu > .wsmenu-list > li {
		/*display: block;*/
		/*float: left;*/
		/*padding: 0;*/
		/*margin: 0;*/
		/*display: inline-flex;*/
		/*float: none;*/
	}

	.wsmenu > .wsmenu-list > li > .navtext {
		/*color: $blue;*/
		/*display: block;*/
		/*font-size: 1rem;*/
		/*font-weight: 400;*/
		/*line-height: 16px;*/
		/*margin: 0px;*/
		/*padding: 14px 20px;*/
		text-align: right !important;
		/*white-space: nowrap;*/
		/*width: 100%;*/
	}

	.wsmenu > .wsmenu-list > li > a {
		/*display: block;*/
		/*text-decoration: none;*/
		/*position: relative;*/
	}

	.wsmenu > .wsmenu-list > li > .navtext > span:after {
		/*content: "\f0dd";*/
		/*display: inline-block;*/
		/*font-family: "Font Awesome 5 Pro";*/
		/*font-size: 14px;*/
		/*font-weight: 900;*/
		/*line-height: 10px;*/
		/*margin-left: 1px;*/
		/*position: relative;*/
		right: 8px;
		text-align: right !important;
		/*top: -2px;*/
	}

	.wsmenu > .wsmenu-list > li a:hover .arrow:after {
		/*border-top-color: #b3b3b3;*/
	}

	.wsmenu > .wsmenu-list > li a.active .arrow:after {
		/*border-top-color: #b3b3b3;*/
	}

	.wsmenu > .wsmenu-list > li:hover > a .arrow:after {
		/*border-top-color: #b3b3b3;*/
	}
	/* Main Search Bar CSS*/
	.wsmenu > .wsmenu-list > li.wssearchbar {
		/*width: 40%;*/
		/*float: left;*/
		/*padding-left: 15px;*/
	}

	.wsmenu > .wsmenu-list > li.wssearchbar > .topmenusearch {
		/*float: right;*/
		/*width: 100%;*/
		/*height: 42px;*/
		/*position: relative;*/
		/*margin: 9px 0 0 0;*/
	}

	.wsmenu > .wsmenu-list > li.wssearchbar > .topmenusearch > .searchicon {
		/*-webkit-transition: all 0.7s ease 0s;*/
		/*-moz-transition: all 0.7s ease 0s;*/
		/*-o-transition: all 0.7s ease 0s;*/
		/*transition: all 0.7s ease 0s;*/
	}

	.wsmenu > .wsmenu-list > li.wssearchbar > .topmenusearch > input {
		/*width: 100%;*/
		/*position: relative;*/
		/*float: right;*/
		/*top: 0;*/
		/*right: 0;*/
		/*bottom: 0;*/
		/*border: 0;*/
		/*padding: 0;*/
		/*margin: 0;*/
		/*text-indent: 15px;*/
		/*font-size: 1rem;*/
		/*height: 42px;*/
		/*z-index: 2;*/
		/*outline: none;*/
		/*color: #7d7d7d;*/
		/*-webkit-transition: all 0.7s ease 0s;*/
		/*-moz-transition: all 0.7s ease 0s;*/
		/*-o-transition: all 0.7s ease 0s;*/
		/*transition: all 0.7s ease 0s;*/
		/*-webkit-border-radius: 2px;*/
		/*-moz-border-radius: 2px;*/
		/*border-radius: 2px;*/
	}

	.wsmenu > .wsmenu-list > li.wssearchbar > .topmenusearch > input:focus ~ .btnstyle {
		/*color: #fff;*/
		/*opacity: 0.9;*/
	}

	.wsmenu > .wsmenu-list > li.wssearchbar > .topmenusearch > input:focus ~ .searchicon {
		/*opacity: 1;*/
		/*z-index: 3;*/
		/*color: #fff;*/
	}

	.wsmenu > .wsmenu-list > li.wssearchbar > .topmenusearch > .btnstyle {
		/*top: 0px;*/
		/*position: absolute;*/
		/*right: 0;*/
		/*bottom: 0;*/
		/*width: 49px;*/
		/*line-height: 30px;*/
		/*z-index: 1;*/
		/*cursor: pointer;*/
		/*color: #fff;*/
		/*z-index: 1000;*/
		/*border: solid 0px;*/
		/*-webkit-transition: all 0.7s ease 0s;*/
		/*-moz-transition: all 0.7s ease 0s;*/
		/*-o-transition: all 0.7s ease 0s;*/
		/*transition: all 0.7s ease 0s;*/
		/*-webkit-border-radius: 0px 2px 2px 0px;*/
		/*-moz-border-radius: 0px 2px 2px 0px;*/
		/*border-radius: 0px 2px 2px 0px;*/
	}

	.wsmenu > .wsmenu-list > li.wssearchbar > .topmenusearch > .btnstyle > .fa {
		/*line-height: 38px;*/
		/*margin: 0;*/
		/*padding: 0;*/
		/*text-align: center;*/
		/*color: #9c9c9c;*/
	}
	/* My Account Dropdown */
	.wsmenu > .wsmenu-list > li > ul.sub-menu {
		/*position: absolute;*/
		/*top: 60px;*/
		/*z-index: 1000;*/
		/*margin: 0;*/
		/*padding: 0;*/
		/*min-width: 190px;*/
		/*background-color: #fff;*/
		/*border: solid 1px #eeeeee;*/
	}

	.wsmenu > .wsmenu-list > li > ul.sub-menu > li {
		/*position: relative;*/
		/*margin: 0;*/
		/*padding: 0;*/
		/*display: block;*/
	}

	.wsmenu > .wsmenu-list > li > ul.sub-menu > li > a {
		/*background-image: none;*/
		/*color: #666666;*/
		/*border-right: 0 none;*/
		text-align: right !important;
		/*display: block;*/
		/*line-height: 22px;*/
		/*padding: 8px 12px;*/
		/*text-transform: none;*/
		/*font-size: 1rem;*/
		/*letter-spacing: normal;*/
		/*border-right: 0px solid;*/
	}

	.wsmenu > .wsmenu-list > li > ul.sub-menu > li > a:hover {
		/*text-decoration: none;*/
	}

	.wsmenu > .wsmenu-list > li > ul.sub-menu > li > a > .fa {
		/*margin-right: 9px;*/
	}

	.wsmenu > .wsmenu-list > li.wsshopmyaccount {
		/*float: right;*/
	}

	.wsmenu > .wsmenu-list > li.wsshopmyaccount > a {
		/*display: block;*/
		/*padding: 0 24px 0 24px;*/
		/*line-height: 59px;*/
		/*text-decoration: none;*/
		/*position: relative;*/
	}

	.wsmenu > .wsmenu-list > .wsshopmyaccount > a .fa.fa-angle-down {
		/*display: inline-block;*/
		/*font-size: 1rem;*/
		/*line-height: inherit;*/
		/*margin-left: 8px;*/
		/*color: #7b7b7b;*/
	}

	.wsmenu > .wsmenu-list > .wsshopmyaccount > a .fa {
		/*display: inline-block;*/
		/*font-size: 16px;*/
		/*line-height: inherit;*/
		/*margin-right: 11px;*/
		/*vertical-align: middle;*/
	}
	/* Main Menu Cart Icon*/
	.wsmenu > .wsmenu-list > li.wscarticon {
		/*float: right;*/
		/*clear: right;*/
	}

	.wsmenu > .wsmenu-list > li.wscarticon > a {
		/*display: block;*/
		/*color: #828181;*/
		/*padding: 0 26px 0 22px;*/
		/*line-height: 60px;*/
		/*text-decoration: none;*/
		/*position: relative;*/
	}

	.wsmenu > .wsmenu-list > li.wscarticon .hidetxt {
		/*display: none;*/
	}

	.wsmenu > .wsmenu-list > li.wscarticon a {
		/*padding: 0 27px 0 23px;*/
		/*text-align: center;*/
		/*border-left: 1px solid rgba(0, 0, 0, 0.1);*/
		/*border-right: 1px solid rgba(0, 0, 0, 0.1);*/
	}

	.wsmenu > .wsmenu-list > li.wscarticon a .fa {
		/*font-size: 15px;*/
	}

	.wsmenu > .wsmenu-list > li.wscarticon em.roundpoint {
		/*position: absolute;*/
		/*top: 14px;*/
		/*right: 21px;*/
		/*width: 17px;*/
		/*height: 17px;*/
		/*background-color: #DA4B38;*/
		/*-webkit-border-radius: 50%;*/
		/*-moz-border-radius: 50%;*/
		/*border-radius: 50%;*/
		/*font-size: 9px;*/
		/*text-align: center;*/
		/*font-style: normal;*/
		/*line-height: 16px;*/
		/*color: #fff;*/
	}

	.wsmenu > .wsmenu-list > .wsshopmenu > a .fa.fa-shopping-basket {
		/*display: inline-block;*/
		/*font-size: 1rem;*/
		/*line-height: inherit;*/
		/*margin-right: 8px;*/
		/*color: #7b7b7b;*/
	}
	/* ================== Desktop Tabing Mega Menus CSS  ================== */
	/* Tabing Leftside */
	.wsmenu > .wsmenu-list > li > .wsshoptabing {
		/*width: 100%;*/
		text-align: right !important;
		/*margin: 0px;*/
		/*padding: 0px;*/
		/*position: absolute;*/
		/*top: 46px;*/
		/*background-color: #f8f8f8;*/
		/*left: 0;*/
		/*border-bottom: solid 2px #376cb7;*/
		/*border-top: solid 0px;*/
		/*z-index: 101;*/
	}
	//this is the background to the sub nav
	.wsmenu > .wsmenu-list > li > .wsshoptabing > .wsshopwp {
		/*width: 100%;*/
		/*display: block;*/
		/*position: relative;*/
		/*background-color: $light-grey;*/
	}

	.wsmenu > .wsmenu-list > li > .wsshoptabing.wtsdepartmentmenu {
		/*background: #fff;*/
		/*border-bottom: solid 1px $blue;*/
	}

	.wsmenu > .wsmenu-list > li > .wsshoptabing > .wsshopwp > .wstabitem {
		/*display: block;*/
		/*margin: 0px;*/
		/*padding: 0px;*/
		/*font-size: 1rem;*/
	}

	.wsmenu > .wsmenu-list > li > .wsshoptabing > .wsshopwp > .wstabitem > li:before, .wsmenu > .wsmenu-list > li > .wsshoptabing > .wsshopwp > .wstabitem > li:after {
		/*content: "";*/
		/*display: table;*/
	}

	.wsmenu > .wsmenu-list > li > .wsshoptabing > .wsshopwp > .wstabitem > li:after {
		/*clear: both;*/
	}

	.wsmenu > .wsmenu-list > li > .wsshoptabing > .wsshopwp > .wstabitem > li {
		/*width: 100%;*/
		/*list-style: none;*/
	}

	.wsmenu > .wsmenu-list > li > .wsshoptabing > .wsshopwp > .wstabitem > li > a {
		/*display: block;*/
		/*padding: 15px 11px;*/
		/*font-size: 1rem;*/
		/*color: #606060;*/
		/*border-bottom: solid 1px $blue;*/
		/*width: 23%;*/
		float: right !important;
		/*position: relative;*/
		/*background-color: $light-grey;*/
	}

	.wsmenu > .wsmenu-list > li > .wsshoptabing > .wsshopwp > .wstabitem > li:hover a {
		/*text-decoration: none;*/
	}

	.wsmenu > .wsmenu-list > li > .wsshoptabing > .wsshopwp > .wstabitem > li:hover > a {
		/*text-decoration: none;*/
	}

	.wsmenu > .wsmenu-list > li > .wsshoptabing > .wsshopwp > .wstabitem > li > a > .fa {
		/*color: #848484;*/
		/*margin-right: 5px;*/
		/*text-align: center;*/
		/*width: 24px;*/
		/*font-size: 16px;*/
	}
	/* Tabing Rightside */
	.wsmenu > .wsmenu-list > li > .wsshoptabing > .wsshopwp > .wstabitem > li > .wstitemright {
		/*opacity: 0;*/
		/*visibility: hidden;*/
		/*position: absolute;*/
		/*right: 0;*/
		/*top: 0;*/
		/*background-color: $grey;*/
		/*color: $blue;*/
		/*display: block;*/
		/*float: left;*/
		/*padding: 10px 8px 0px 8px;*/
		/*width: 77%;*/
		/*min-height: 400px;*/
		right: auto !important;
		left: 0 !important;
	}

	.wsmenu > .wsmenu-list > li:hover > .wsshoptabing > .wsshopwp > .wstabitem > li.wsshoplink-active > .wstitemright {
		/*opacity: 1;*/
		/*visibility: visible;*/
	}
	/* Mega Menu Content Formatting */
	.carousel-caption {
		/*width: 100%;*/
		/*display: block;*/
		/*text-align: center;*/
		/*right: 0;*/
		/*left: 0;*/
		/*margin: 0;*/
		/*padding: 0;*/
		/*bottom: 0;*/
	}

	.carousel-caption h3 {
		/*width: 100%;*/
		/*display: block;*/
		/*padding: 7px 0px;*/
		/*background-color: rgba(0, 0, 0, 0.5);*/
	}

	.wsmenu > .wsmenu-list > li .wstbootslider {
		/*float: right;*/
		/*margin-top: 8px;*/
	}

	.wsmenu > .wsmenu-list > li .wstmegamenucolr {
		/*display: block;*/
		/*float: right;*/
		/*margin-top: 8px;*/
	}

	.wsmenu > .wsmenu-list > li .wstheading {
		/*width: 100%;*/
		/*display: block;*/
		/*padding: 10px 0px 6px 0px;*/
		/*font-size: 1rem;*/
		/*color: #333333;*/
		/*border-bottom: solid 1px $green;*/
		/*margin-bottom: 12px;*/
		/*text-transform: uppercase;*/
	}

	.wsmenu > .wsmenu-list > li .wstliststy01 {
		/*padding: 0px 0px 8px 0px;*/
		/*margin: 0px;*/
	}

	.wsmenu > .wsmenu-list > li .wstliststy01 li {
		/*width: 33.33%;*/
		/*float: left;*/
		/*line-height: 23px;*/
		/*font-size: 14px;*/
		/*list-style: none;*/
		/*padding: 0px 0px 0px 0px;*/
		/*margin: 0px;*/
	}

	.wsmenu > .wsmenu-list > li .wstliststy01 li a {
		/*color: #6e6e6e;*/
		/*font-size: 1rem;*/
	}

	.wsmenu > .wsmenu-list > li .wstliststy01 li a:hover {
		/*color: $blue;*/
		/*text-decoration: none;*/
	}

	.wsmenu > .wsmenu-list > li .wstliststy02 {
		/*padding: 5px 0px 10px 0px;*/
		/*margin: 0px;*/
		text-align: right !important;
	}

	.wsmenu > .wsmenu-list > li .wstliststy02 li {
		/*width: 100%;*/
		/*list-style: none;*/
		/*line-height: 25px;*/
	}

	.wsmenu > .wsmenu-list > li .wstliststy02 li a {
		/*color: $blue;*/
		/*font-size: 1rem;*/
	}

	.wsmenu > .wsmenu-list > li .wstliststy02 li a:hover {
		/*color: $blue;*/
		/*text-decoration: none;*/
	}

	.wsmenu > .wsmenu-list > li .wstliststy02 li.wstheading {
		/*line-height: normal;*/
		/*padding-left: 0px;*/
		/*margin-top: 0px;*/
		/*text-transform: none;*/
		/*font-weight: 700;*/
	}

	.wsmenu > .wsmenu-list > li .wstliststy02 li a .wstcount {
		/*font-size: 10px;*/
		/*color: #adadad;*/
	}

	.wsmenu > .wsmenu-list > li .wstliststy06 li.wstheading {
		/*line-height: normal;*/
		/*padding-left: 0px;*/
	}

	.wsmenu > .wsmenu-list > li .wstliststy06 {
		/*padding: 0px 0px 0px 0px;*/
		/*margin: 0px;*/
	}

	.wsmenu > .wsmenu-list > li .wstliststy06 li {
		/*width: 100%;*/
		/*list-style: none;*/
		/*line-height: 24px;*/
	}

	.wsmenu > .wsmenu-list > li .wstliststy06 li a {
		/*color: #6e6e6e;*/
		/*font-size: 1rem;*/
	}

	.wsmenu > .wsmenu-list > li .wstliststy06 li a:hover {
		/*color: $blue;*/
		/*text-decoration: none;*/
	}

	.wsmenu > .wsmenu-list > li .wstliststy06 li.wstheading {
		/*line-height: normal;*/
	}

	.wsmenu > .wsmenu-list > li .wstliststy06 li a .wstcount {
		/*font-size: 10px;*/
		/*color: #adadad;*/
	}

	.wsmenu > .wsmenu-list > li .wstliststy03 {
		/*width: 100%;*/
		/*padding: 0px 0px 8px 0px;*/
		/*margin: 0px;*/
	}

	.wsmenu > .wsmenu-list > li .wstliststy03 li {
		/*width: 33.33%;*/
		/*float: left;*/
		/*line-height: 23px;*/
		/*font-size: 14px;*/
		/*list-style: none;*/
		/*padding: 0px 0px 0px 0px;*/
		/*margin: 0px;*/
	}

	.wsmenu > .wsmenu-list > li .wstliststy03 li a {
		/*color: #6e6e6e;*/
		/*font-size: 1rem;*/
	}

	.wsmenu > .wsmenu-list > li .wstliststy03 li a:hover {
		/*color: $blue;*/
		/*text-decoration: none;*/
	}

	.wsmenu > .wsmenu-list > li .wstliststy04 {
		/*padding: 14px 0px 0px 0px;*/
		/*margin: 0px;*/
	}

	.wsmenu > .wsmenu-list > li .wstliststy04 li {
		/*width: 100%;*/
		/*list-style: none;*/
		/*line-height: 22px;*/
	}

	.wsmenu > .wsmenu-list > li .wstliststy04 li a {
		/*color: #6e6e6e;*/
		/*font-size: 1rem;*/
	}

	.wsmenu > .wsmenu-list > li .wstliststy04 li a:hover {
		/*color: $blue;*/
		/*text-decoration: none;*/
	}

	.wsmenu > .wsmenu-list > li .wstliststy04 li.wstheading {
		/*line-height: normal;*/
		/*text-align: center;*/
		/*padding: 12px 0px;*/
		/*font-size: 1rem;*/
		/*margin-bottom: 12px;*/
	}

	.wsmenu > .wsmenu-list > li .wstliststy04 li a .wstcount {
		/*font-size: 10px;*/
		/*color: #adadad;*/
	}

	.wsmenu > .wsmenu-list > li .wstliststy05 {
		/*padding: 10px 0px 0px 0px;*/
		/*margin: 0px;*/
	}

	.wsmenu > .wsmenu-list > li .wstmegamenucolr03 {
		/*padding-top: 10px;*/
		/*padding-left: 20px;*/
	}

	.wsmenu > .wsmenu-list > li .wstliststy05 li {
		/*width: 100%;*/
		/*list-style: none;*/
		/*line-height: 22px;*/
	}

	.wsmenu > .wsmenu-list > li .wstliststy05 li a {
		/*color: #6e6e6e;*/
		/*font-size: 1rem;*/
	}

	.wsmenu > .wsmenu-list > li .wstliststy05 li a:hover {
		/*color: $blue;*/
		/*text-decoration: none;*/
	}

	.wsmenu > .wsmenu-list > li .wstliststy05 li.wstheading {
		/*line-height: normal;*/
	}

	.wsmenu > .wsmenu-list > li .wstliststy05 li a .wstcount {
		/*font-size: 10px;*/
		/*color: #adadad;*/
	}

	.wsmenu > .wsmenu-list > li .wstmorebtn {
		/*border-radius: 2px;*/
		/*color: #9b9b9b;*/
		/*display: inline-block;*/
		/*float: right;*/
		/*font-size: 10px;*/
		/*font-weight: normal;*/
		/*letter-spacing: 0;*/
		/*padding: 1px 7px;*/
		text-align: right !important;
		/*text-transform: none;*/
	}

	.wsmenu > .wsmenu-list > li .wstmenutag {
		/*height: 18px;*/
		/*line-height: 18px;*/
		/*text-align: center;*/
		/*font-size: 11px;*/
		/*color: #fff;*/
		/*border-radius: 2px;*/
		/*position: relative;*/
		/*font-weight: normal;*/
		/*padding: 1px 6px 1px 6px;*/
		/*margin-left: 6px;*/
		/*text-transform: none;*/
		/*letter-spacing: -0.0px;*/
	}

	.wsmenu > .wsmenu-list > li .wstmenutag:after {
		/*-moz-border-bottom-colors: none;*/
		/*-moz-border-left-colors: none;*/
		/*-moz-border-right-colors: none;*/
		/*-moz-border-top-colors: none;*/
		/*border-image: none;*/
		/*border-style: solid;*/
		/*border-width: 3px;*/
		/*content: "";*/
		/*left: -6px;*/
		/*margin-top: 0px;*/
		/*position: absolute;*/
		/*top: 4px;*/
		/*z-index: 1;*/
	}

	.wsmenu > .wsmenu-list > li .wstmenutag.redtag {
		/*background-color: #fe7b8f;*/
	}

	.wsmenu > .wsmenu-list > li .wstmenutag.redtag:after {
		/*border-color: transparent #fe7b8f transparent transparent;*/
	}

	.wsmenu > .wsmenu-list > li .wstmenutag.greentag {
		/*background-color: #00c853;*/
	}

	.wsmenu > .wsmenu-list > li .wstmenutag.greentag:after {
		/*border-color: transparent #00c853 transparent transparent;*/
	}

	.wsmenu > .wsmenu-list > li .wstmenutag.bluetag {
		/*background-color: #4fc3f7;*/
	}

	.wsmenu > .wsmenu-list > li .wstmenutag.bluetag:after {
		/*border-color: transparent #4fc3f7 transparent transparent;*/
	}

	.wsmenu > .wsmenu-list > li .wstmenutag.orangetag {
		/*background-color: #FE7000;*/
	}

	.wsmenu > .wsmenu-list > li .wstmenutag.orangetag:after {
		/*border-color: transparent #FE7000 transparent transparent;*/
	}

	.wsmenu > .wsmenu-list > li .kitchenmenuimg {
		/*background-image: url(../images/kitchen-menu-img.png);*/
		/*background-position: bottom right;*/
		/*background-repeat: no-repeat;*/
	}

	.wsmenu > .wsmenu-list > li .computermenubg {
		/*background-image: url(../images/computer-menu-img.png);*/
		/*background-position: bottom right;*/
		/*background-repeat: no-repeat;*/
	}

	.wsmenu > .wsmenu-list > li .wstpngsml {
		/*padding-left: 18px;*/
	}

	.wsmenu > .wsmenu-list > li .wstfullwtag {
		/*width: 100%;*/
		/*display: block;*/
		/*border-bottom: solid 1px #e5e5e5;*/
		/*background-color: #f5f5f5;*/
	}
	/* Brand Mega Menu */
	.wsmenu > .wsmenu-list > li .wstbrandbottom {
		/*width: 100%;*/
		/*background-color: #fff;*/
		/*display: block;*/
		/*opacity: 0;*/
		/*position: absolute;*/
		/*right: 0;*/
		/*visibility: hidden;*/
		/*padding: 9px 6px;*/
	}

	.wsmenu-list > li:hover > .wtsbrandmenu > .wsshoptabingwp > .wstabitem02 > li.wsshoplink-active > .wstbrandbottom {
		/*opacity: 1;*/
		/*visibility: visible;*/
	}

	.wsmenu > .wsmenu-list > li .wstabitem02 {
		/*width: 100%;*/
		/*padding: 0px;*/
		/*margin: 0px 0px;*/
		/*list-style: none;*/
		/*display: table;*/
	}

	.wsmenu > .wsmenu-list > li .wstabitem02 > li {
		/*display: table-cell;*/
		/*list-style: outside none none;*/
		/*text-align: center;*/
	}

	.wsmenu > .wsmenu-list > li .wstabitem02 > li > a {
		/*display: block;*/
		/*padding: 14px 0px;*/
		/*font-size: 1rem;*/
		/*color: #717171;*/
		/*background-color: #f5f5f5;*/
		/*border-bottom: 1px solid #e5e5e5;*/
		/*position: relative;*/
	}

	.wsmenu > .wsmenu-list > li .wstabitem02 > .wsshoplink-active > a:after {
		/*position: absolute;*/
		/*content: '';*/
		/*top: 42px;*/
		/*right: 50%;*/
		/*width: 13px;*/
		/*height: 13px;*/
		/*transform: rotate(225deg);*/
		/*-webkit-transform: rotate(225deg);*/
		/*-moz-transform: rotate(225deg);*/
		/*-o-transform: rotate(225deg);*/
		/*-ms-transform: rotate(225deg);*/
		/*border-right: 1px solid #dbdbdb;*/
		/*border-bottom: 1px solid #dbdbdb;*/
		/*z-index: 100;*/
		/*background-color: #fff;*/
	}

	.wsmenu > .wsmenu-list > li .wstabitem02 > li:hover a {
		/*text-decoration: none;*/
		/*-webkit-border-radius: 4px 0px 0px 4px;*/
		/*-moz-border-radius: 4px 0px 0px 4px;*/
		/*border-radius: 4px 0px 0px 4px;*/
	}

	.wsmenu > .wsmenu-list > li .wstabitem02 > .wsshoplink-active > a {
		/*text-decoration: none;*/
		/*-webkit-border-radius: 4px 0px 0px 4px;*/
		/*-moz-border-radius: 4px 0px 0px 4px;*/
		/*border-radius: 4px 0px 0px 4px;*/
		/*background-color: #f5f5f5;*/
		/*color: #333;*/
	}

	.wsmenu > .wsmenu-list > li .wstabitem02 > .wsshoplink-active > a > .fa {
		/*opacity: 1;*/
	}

	.wsmenu > .wsmenu-list > li .wstabitem02 > li:hover a {
		/*text-decoration: none;*/
		/*-webkit-border-radius: 4px 0px 0px 4px;*/
		/*-moz-border-radius: 4px 0px 0px 4px;*/
		/*border-radius: 4px 0px 0px 4px;*/
	}

	.wsmenu > .wsmenu-list > li .wstabitem02 > li > a .fa {
		/*margin-right: 5px;*/
		/*text-align: center;*/
		/*width: 25px;*/
		/*font-size: 17px;*/
		/*opacity: 0.5;*/
	}

	.wsmenu > .wsmenu-list > li .brandcolor01 {
		/*color: #424242;*/
	}

	.wsmenu > .wsmenu-list > li .brandcolor02 {
		/*color: #00bcf2;*/
	}

	.wsmenu > .wsmenu-list > li .brandcolor03 {
		/*color: #00aff0;*/
	}

	.wsmenu > .wsmenu-list > li .brandcolor04 {
		/*color: #003087;*/
	}

	.wsmenu > .wsmenu-list > li .brandcolor05 {
		/*color: #a82400;*/
	}

	.wsmenu > .wsmenu-list > li .brandcolor06 {
		/*color: #ff3300;*/
	}

	.wsmenu > .wsmenu-list > li .brandcolor07 {
		/*color: #7ac142;*/
	}

	.wsmenu > .wsmenu-list > li .brandcolor08 {
		/*color: #ef4056;*/
	}
	/* ================== Desktop Mega Menus CSS  ================== */
	.wsmenu > .wsmenu-list > li > .wsmegamenu {
		/*width: 100%;*/
		/*left: 0px;*/
		/*position: absolute;*/
		/*top: 60px;*/
		/*color: $blue;*/
		/*z-index: 1000;*/
		/*margin: 0px;*/
		text-align: right !important;
		/*padding: 14px 5px;*/
		/*font-size: 15px;*/
		/*border: solid 1px #eeeeee;*/
		/*background-color: #fff;*/
	}

	.wsmenu > .wsmenu-list > li > .wsmegamenu .title {
		/*border-bottom: 1px solid #CCC;*/
		/*font-size: 14px;*/
		/*padding: 9px 5px 9px 0px;*/
		/*font-size: 17px;*/
		/*color: #424242;*/
		/*margin: 0px 0px 7px 0px;*/
		text-align: right !important;
		/*height: 39px;*/
	}

	.wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li {
		/*display: block;*/
		/*text-align: center;*/
		/*white-space: nowrap;*/
		text-align: right !important;
	}

	.wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li a {
		/*line-height: 18px;*/
		/*border-right: none;*/
		text-align: right !important;
		/*padding: 6px 0px;*/
		/*background: #fff;*/
		/*background-image: none;*/
		/*color: #666666;*/
		/*border-right: 0 none;*/
		/*display: block;*/
		/*background-color: #fff;*/
		/*color: #424242;*/
	}

	.wsmenu > .wsmenu-list > li > .wsmegamenu li .fa {
		/*margin-right: 5px;*/
		/*text-align: center;*/
		/*width: 18px;*/
	}

	.wsmenu > .wsmenu-list > li > .wsmegamenu li a:hover {
		/*background: transparent;*/
		/*text-decoration: underline;*/
	}

	.wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li .fa {
		/*font-size: 11px;*/
	}

	.wsmenu > .wsmenu-list > li > .wsmegamenu li .fa {
		/*margin-right: 5px;*/
		/*text-align: center;*/
		/*width: 18px;*/
	}

	.wsmenu > .wsmenu-list > li > .wsmegamenu .mrgtop {
		/*margin-top: 15px;*/
	}

	.wsmenu > .wsmenu-list > li > .wsmegamenu .show-grid div {
		/*padding-bottom: 10px;*/
		/*padding-top: 10px;*/
		/*background-color: #dbdbdb;*/
		/*border: 1px solid #e7e7e7;*/
		/*color: #6a6a6a;*/
		/*margin: 2px 0px;*/
		/*font-size: 1rem;*/
	}
	/* ================== Desktop Half Menus CSS  ================== */
	.wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu {
		/*width: 40%;*/
		/*right: auto;*/
		/*left: auto;*/
	}

	.wsmenu > .wsmenu-list > li > .wsmegamenu.halfdiv {
		/*width: 35%;*/
		/*right: 0px;*/
		/*left: auto;*/
	}
	/* ================== Desktop Extra CSS ================== */
	.wsmobileheader {
		/*display: none;*/
	}

	.overlapblackbg {
		/*opacity: 0;*/
		/*visibility: hidden;*/
	}

	.wsmenu .wsmenu-click {
		/*display: none;*/
	}

	.wsmenu .wsmenu-click02 {
		/*display: none;*/
	}

	.wsmenu .wsmenu-click03 {
		/*display: none;*/
	}

	.hometext {
		/*display: none;*/
	}
	/*==============================================================================
Start Mobile CSS
===============================================================================*/
	/* ================== Mobile Menu Change Brake Point ================== */
	@media only screen and (max-width: 991px) {
		/* ================== Mobile Base CSS ================== */
		html {
			/*overflow: hidden;*/
			/*height: 100%;*/
			/*-webkit-overflow-scrolling: touch;*/
		}

		body {
			/*height: 100%;*/
			/*overflow-y: auto;*/
			/*overflow-x: hidden;*/
		}

		body.wsactive {
			/*overflow: hidden;*/
		}
		/* ================== Mobile Main Menu CSS ================== */
		.smllogo {
			/*display: none;*/
		}

		.wsmain {
			/*margin: 0px;*/
			/*background-color: transparent;*/
		}

		.wsmenu {
			/*width: 100%;*/
			/*background: rgba(0, 0, 0, 0) none repeat scroll 0 0;*/
			/*left: 0;*/
			/*overflow-y: hidden;*/
			/*padding: 0;*/
			/*top: 0;*/
			/*visibility: hidden;*/
			/*position: fixed;*/
			/*margin: 0px;*/
			/*border-left: none;*/
		}

		.wsmenu > .wsmenu-list {
			/*height: auto;*/
			/*min-height: 100%;*/
			/*width: 260px;*/
			/*background: #fff;*/
			/*padding-bottom: 0;*/
			/*margin-right: -260px;*/
			/*display: block;*/
			/*text-align: center;*/
			/*-webkit-transition: all 0.25s ease-in-out;*/
			/*-moz-transition: all 0.25s ease-in-out;*/
			/*-o-transition: all 0.25s ease-in-out;*/
			/*-ms-transition: all 0.25s ease-in-out;*/
			/*transition: all 0.25s ease-in-out;*/
			/*position: static;*/
		}

		.wsmenu > .wsmenu-list > li {
			/*width: 100%;*/
			/*display: block;*/
			/*float: none;*/
			/*border-right: none;*/
			/*background-color: transparent;*/
			/*position: relative;*/
			/*white-space: inherit;*/
		}

		.wsmenu > .wsmenu-list > li > a {
			/*padding: 9px 32px 9px 18px;*/
			/*font-size: 14px;*/
			text-align: right !important;
			/*border-right: solid 0px;*/
			/*background-color: transparent;*/
			/*color: #666666;*/
			/*line-height: 25px;*/
			/*border-bottom: 1px solid;*/
			/*position: static;*/
			/*border-top: 0px solid red !important;*/
		}

		.wsmenu > .wsmenu-list > li > .navtext {
			/*padding-left: 18px;*/
		}

		.wsmenu > .wsmenu-list > li > .navtext > span:after {
			/*display: none;*/
		}

		.wsmenu > .wsmenu-list > li.wscarticon a {
			/*padding-left: 18px;*/
		}

		.wsmenu > .wsmenu-list > li.wsshopmyaccount > a {
			/*padding-left: 18px;*/
		}

		.wsmenu > .wsmenu-list > li > a > .fa {
			/*font-size: 16px;*/
			/*color: #bfbfbf;*/
		}

		.wsmenu > .wsmenu-list > li.wscarticon a .fa {
			/*margin-right: 7px;*/
		}

		.wsmenu > .wsmenu-list > li > a .wsarrow:after {
			/*display: none;*/
		}

		.wsmenu > .wsmenu-list > li.wscarticon em.roundpoint {
			/*display: inline-block;*/
			/*right: auto;*/
			/*left: 27px;*/
		}

		.wsmenu > .wsmenu-list > li:hover > a {
			/*background-color: rgba(0, 0, 0, 0.08);*/
			/*border-top: 0px solid red !important;*/
		}

		.wsmenu > .wsmenu-list > li > a > .hometext {
			/*display: inline-block;*/
		}

		.wsmenu > .wsmenu-list > li.wscarticon .hidetxt {
			/*display: inline-block;*/
		}

		.wsmenu > .wsmenu-list > li.wssearchbar {
			/*display: none;*/
		}

		.wsmenu .wsmenu-list > li.wsshopmyaccount > a > .fa-angle-down {
			/*display: none;*/
		}

		.wsmenu > .wsmenu-list > li.wscarticon {
			/*float: none;*/
		}

		.wsmenu > .wsmenu-list > li.wsshopmyaccount {
			/*float: none;*/
		}

		.wsmenu > .wsmenu-list > li.wscarticon a {
			text-align: right !important;
		}
		/* ================== Mobile Dropdown CSS ================== */
		.wsmenu > .wsmenu-list > li > ul.sub-menu {
			/*display: none;*/
			/*position: relative;*/
			/*top: 0px;*/
			/*background-color: #fff;*/
			/*border-bottom: solid 1px #ccc;*/
			/*padding: 0px;*/
			/*opacity: 1;*/
			/*visibility: visible;*/
			/*-webkit-transform: none;*/
			/*-moz-transform: none;*/
			/*-ms-transform: none;*/
			/*-o-transform: none;*/
			/*transform: none;*/
			/*-webkit-transition: inherit;*/
			/*-moz-transition: inherit;*/
			/*transition: inherit;*/
			/*-webkit-transform-style: flat;*/
		}

		.wsmenu > .wsmenu-list > li > ul.sub-menu > li > a {
			/*line-height: 20px;*/
			/*font-size: 1rem;*/
			/*padding: 13px 0px 13px 16px;*/
			/*color: #6e6e6e;*/
			/*border-bottom: solid 1px rgba(0, 0, 0, 0.13);*/
		}

		.wsmenu > .wsmenu-list > li > ul.sub-menu > li span + a {
			/*padding-right: 30px;*/
		}

		.wsmenu > .wsmenu-list > li > ul.sub-menu > li > a:hover {
			/*background-color: #e7e7e7;*/
			/*color: #666666;*/
			/*text-decoration: underline;*/
		}

		.wsmenu > .wsmenu-list > li > ul.sub-menu li:hover > a {
			/*background-color: #e7e7e7;*/
			/*color: #666666;*/
		}
		/* ================== Mobile Mega Menu CSS  ================== */
		.wsmenu > .wsmenu-list > li > .wsmegamenu {
			/*background-color: #fff;*/
			/*padding-top: 5px;*/
			/*color: #666666;*/
			/*display: none;*/
			/*position: relative;*/
			/*top: 0px;*/
			/*padding: 8px 0px 8px 0px;*/
			/*border: solid 0px;*/
			/*opacity: 1;*/
			/*visibility: visible;*/
			/*transform: none;*/
			/*-o-transform: none;*/
			/*-moz-transform: none;*/
			/*-webkit-transform: none;*/
			/*border-bottom: 1px solid rgba(0, 0, 0, 0.13);*/
			/*-webkit-transform-style: flat;*/
		}

		.wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu {
			/*width: 100%;*/
		}

		.wstabitem02 > .wsshoplink-active > a:after {
			/*display: none;*/
		}

		.wsmenu > .wsmenu-list > li > .wsshoptabing > .wsshopwp > .wstabitem > li {
			/*position: relative;*/
		}

		.wsmenu > .wsmenu-list > li > .wsshoptabing > .wsshopwp > .wstabitem > li > a {
			/*width: 100%;*/
			/*margin: 0px;*/
			/*float: none;*/
			/*font-size: 1rem;*/
			/*padding: 11px 11px 10px 11px;*/
		}

		.wsmenu > .wsmenu-list > li .wstheading {
			/*font-weight: normal;*/
			/*padding-left: 0px;*/
			/*padding-right: 0px;*/
		}

		.wsmenu > .wsmenu-list > li > .wsshoptabing > .wsshopwp > .wstabitem > li > .wstitemright {
			/*width: 100%;*/
			/*position: static;*/
			/*top: 0px;*/
			/*min-height: inherit;*/
			/*padding: 10px 0px;*/
			/*opacity: 1;*/
			/*visibility: visible;*/
			/*display: none;*/
			/*background-color: #fff;*/
			/*border-bottom: 1px solid rgba(0, 0, 0, 0.13);*/
		}

		.wsmenu > .wsmenu-list > li .wstbootslider {
			/*width: auto;*/
		}

		.wsmenu > .wsmenu-list > li .wstmegamenucolr {
			/*width: auto;*/
		}

		.wsmenu > .wsmenu-list > li .wstliststy01 > li {
			/*width: 100%;*/
			/*padding: 0px;*/
			/*margin: 0px;*/
		}

		.wsmenu > .wsmenu-list > li .wstliststy02 {
			/*width: 100%;*/
			/*padding: 0px;*/
			/*margin: 0px 0px 5px 0px;*/
		}

		.wsmenu > .wsmenu-list > li .wstliststy03 li {
			/*width: 100%;*/
		}

		.wsmenu > .wsmenu-list > li .wstliststy05 {
			/*width: 100%;*/
			/*padding: 0px;*/
			/*margin-bottom: 10px;*/
		}

		.wsmenu > .wsmenu-list > li .kitchenmenuimg {
			/*background-image: none;*/
		}

		.wsmenu > .wsmenu-list > li .computermenubg {
			/*background-image: none;*/
		}

		.wsmenu > .wsmenu-list > li .wstbrandbottom {
			/*width: 100%;*/
			/*position: static;*/
			/*top: 0px;*/
			/*min-height: inherit;*/
			/*padding: 10px 5px 15px 5px;*/
			/*opacity: 1;*/
			/*visibility: visible;*/
			/*display: none;*/
			/*border-bottom: 1px solid rgba(0, 0, 0, 0.13);*/
		}

		.wsmenu > .wsmenu-list > li .wstabitem02 {
			/*display: block;*/
		}

		.wsmenu > .wsmenu-list > li .wstabitem02 > li {
			/*position: relative;*/
			text-align: right !important;
			/*display: block;*/
		}

		.wsmenu > .wsmenu-list > li .wstabitem02 > li > a {
			/*padding: 13px 11px;*/
		}

		.wsmenu > .wsmenu-list > li .wstabitem02 > li > a {
			/*background-color: #fff;*/
		}

		.wsmenu > .wsmenu-list > li .wstheading {
			/*padding-left: 0px;*/
		}

		.wsmenu-list > li > .navtext {
			/*margin: 0px;*/
		}

		.wsmenu .wsmenu-list > li .wsshoptabingwp {
			/*background-color: #fff;*/
			/*padding-left: 0px;*/
			/*padding-right: 0px;*/
		}

		.wsmenu > .wsmenu-list > li > .wsshoptabing {
			/*background-color: #fff;*/
			/*color: #666666;*/
			/*display: none;*/
			/*position: relative;*/
			/*top: 0px;*/
			/*padding: 0px;*/
			/*border: solid 0px;*/
			/*opacity: 1;*/
			/*visibility: visible;*/
			/*transform: none;*/
			/*-o-transform: none;*/
			/*-moz-transform: none;*/
			/*-webkit-transform: none;*/
			/*-webkit-transform-style: flat;*/
		}

		.wsmenu > .wsmenu-list > li > .wsshoptabing > .wsshopwp > .wstabitem {
			/*width: 100%;*/
			/*background-color: #fff;*/
		}

		.wsmenu > .wsmenu-list > li > .wsshoptabing > .wsshopwp > .wstabitem > li > a:after {
			/*border: none;*/
		}

		.wsmenu > .wsmenu-list > li > .wsshoptabing > .wsshopwp > .wstabitem > li > a .fa {
			/*display: none;*/
		}
		/* ================== Mobile Header CSS ================== */
		.wsmobileheader {
			/*width: 100%;*/
			/*display: block;*/
			/*position: fixed;*/
			/*top: 0;*/
			/*left: 0;*/
			/*z-index: 10002;*/
			/*height: 60px;*/
			/*background-color: #eaecf0;*/
			/*text-align: center;*/
			/*-webkit-transition: all 0.25s ease-in-out;*/
			/*-moz-transition: all 0.25s ease-in-out;*/
			/*-o-transition: all 0.25s ease-in-out;*/
			/*-ms-transition: all 0.25s ease-in-out;*/
			/*transition: all 0.25s ease-in-out;*/
			/*box-shadow: 0 0 1px rgba(0, 0, 0, .3);*/
		}

		.wsactive .wsmobileheader {
			/*margin-left: -260px;*/
			/*-webkit-transition: all 0.25s ease-in-out;*/
			/*-moz-transition: all 0.25s ease-in-out;*/
			/*-o-transition: all 0.25s ease-in-out;*/
			/*-ms-transition: all 0.25s ease-in-out;*/
			/*transition: all 0.25s ease-in-out;*/
		}

		.wsmobileheader > .smllogo {
			/*display: block;*/
			/*width: 80px;*/
			/*margin: 7px auto 0px auto;*/
			/*float: none;*/
			/*padding-left: 0px;*/
		}

		.mobiletext {
			/*display: inline-block;*/
		}
		/*Mobile Search Bar*/
		.wsmobileheader .wssearch {
			/*background-color: transparent;*/
			/*z-index: 1000;*/
			/*position: absolute;*/
			/*top: 0px;*/
			/*right: 0px;*/
			/*padding: 18px 22px;*/
			/*cursor: pointer;*/
		}

		.wsmobileheader .wssearch .fa {
			/*font-size: 19px;*/
			/*color: #9196a0;*/
		}

		.wsmobileheader .wssearchform {
			/*display: none;*/
			/*position: absolute;*/
			/*width: 100%;*/
			/*height: 50px;*/
			/*line-height: 44px;*/
			/*top: 0px;*/
			/*left: 0;*/
			/*padding: 13px 15px;*/
			/*cursor: default;*/
		}

		.wsmobileheader .wssearch.wsopensearch {
			/*width: 100%;*/
			/*background-color: $blue;*/
		}

		.wsmobileheader .wssearch.wsopensearch .wssearchform {
			/*display: block;*/
		}

		.wsmobileheader .wssearchform form {
			/*width: calc((100% - 10px) - 45px);*/
			/*display: block;*/
			/*position: relative;*/
		}

		.wsmobileheader .wssearchform form:before {
			/*content: "\f002";*/
			/*font-family: Font Awesome 5 Pro;*/
			/*left: 12px;*/
			/*position: absolute;*/
			/*top: 0;*/
			/*color: #777777;*/
			/*height: 34px;*/
			/*line-height: 33px;*/
		}

		.wsmobileheader .wssearchform input {
			/*width: 100%;*/
			/*height: 34px;*/
			/*-webkit-border-radius: 2px;*/
			/*-moz-border-radius: 2px;*/
			/*border-radius: 2px;*/
			/*border: solid 0px;*/
			/*text-indent: 33px;*/
			/*margin: 0px;*/
			/*line-height: 18px;*/
			/*padding: 0px;*/
			/*display: block;*/
		}

		.wsmobileheader .wssearch.wsopensearch {
			/*left: 0px;*/
			/*right: auto;*/
			/*width: 100%;*/
			/*height: 60px;*/
		}

		.wsmobileheader .wssearch .fa-times {
			/*display: none;*/
		}

		.wsmobileheader .wssearch.wsopensearch .fa-times {
			/*display: inline-block;*/
			/*float: right;*/
			/*font-size: 22px;*/
		}

		.wsmobileheader .wssearch.wsopensearch .fa-search {
			/*display: none;*/
		}
		/* Mobile Toggle Menu icon (X ICON) */
		.wsanimated-arrow {
			/*float: right;*/
			/*cursor: pointer;*/
			/*padding: 22px 15px 12px 15px;*/
			/*margin: 0;*/
			/*width: 55px;*/
			/*height: 55px;*/
			/*-webkit-transition: all 0.4s ease-in-out;*/
			/*-moz-transition: all 0.4s ease-in-out;*/
			/*-o-transition: all 0.4s ease-in-out;*/
			/*-ms-transition: all 0.4s ease-in-out;*/
			/*transition: all 0.4s ease-in-out;*/
		}

		.wsanimated-arrow span,
		.wsanimated-arrow span:before,
		.wsanimated-arrow span:after {
			/*cursor: pointer;*/
			/*height: 2px;*/
			/*width: 23px;*/
			/*background: $white;*/
			/*position: absolute;*/
			/*display: block;*/
			/*content: '';*/
		}

		.wsanimated-arrow span:before {
			/*top: -7px;*/
			/*width: 23px;*/
		}

		.wsanimated-arrow span:after {
			/*bottom: -7px;*/
			/*width: 23px;*/
		}

		.wsanimated-arrow span,
		.wsanimated-arrow span:before,
		.wsanimated-arrow span:after {
			/*transition: all 500ms ease-in-out;*/
		}

		.wsactive .wsanimated-arrow span:after {
			/*width: 23px;*/
		}

		.wsactive .wsanimated-arrow span {
			/*background-color: transparent;*/
		}

		.wsactive .wsanimated-arrow span:before,
		.wsactive .wsanimated-arrow.active span:after {
			/*top: 7px;*/
		}

		.wsactive .wsanimated-arrow span:before {
			/*transform: rotate(45deg);*/
			/*-moz-transform: rotate(45deg);*/
			/*-ms-transform: rotate(45deg);*/
			/*-o-transform: rotate(45deg);*/
			/*-webkit-transform: rotate(45deg);*/
			/*bottom: 0px;*/
		}

		.wsactive .wsanimated-arrow span:after {
			/*transform: rotate(-45deg);*/
			/*-moz-transform: rotate(-45deg);*/
			/*-ms-transform: rotate(-45deg);*/
			/*-o-transform: rotate(-45deg);*/
			/*-webkit-transform: rotate(-45deg);*/
		}
		/* ================== Mobile Overlay/Drawer CSS ================== */
		.wsmenu > .overlapblackbg {
			/*left: 0;*/
			/*width: calc(100% - 260px);*/
			/*height: 100vh;*/
			/*min-height: 100%;*/
			/*position: fixed;*/
			/*top: 0;*/
			/*opacity: 0;*/
			/*visibility: hidden;*/
			/*background-color: rgba(0, 0, 0, 0.45);*/
			/*cursor: pointer;*/
		}

		.wsactive .wsmenu > .overlapblackbg {
			/*opacity: 1;*/
			/*visibility: visible;*/
			/*-webkit-transition: opacity 1.5s ease-in-out;*/
			/*-moz-transition: opacity 1.5s ease-in-out;*/
			/*-ms-transition: opacity 1.5s ease-in-out;*/
			/*-o-transition: opacity 1.5s ease-in-out;*/
		}

		.wsmenucontainer {
			/*-webkit-transition: all 0.25s ease-in-out;*/
			/*-moz-transition: all 0.25s ease-in-out;*/
			/*-o-transition: all 0.25s ease-in-out;*/
			/*-ms-transition: all 0.25s ease-in-out;*/
			/*transition: all 0.25s ease-in-out;*/
		}

		.wsactive .wsmenucontainer {
			/*margin-left: -260px;*/
			/*-webkit-transition: all 0.25s ease-in-out;*/
			/*-moz-transition: all 0.25s ease-in-out;*/
			/*-o-transition: all 0.25s ease-in-out;*/
			/*-ms-transition: all 0.25s ease-in-out;*/
			/*transition: all 0.25s ease-in-out;*/
		}

		.wsactive .wsmenu {
			/*overflow-y: scroll;*/
			/*-webkit-overflow-scrolling: touch;*/
			/*visibility: visible;*/
			/*z-index: 1000;*/
			/*top: 0;*/
		}

		.wsactive .wsmenu > .wsmenu-list {
			/*-webkit-transition: all 0.25s ease-in-out;*/
			/*-moz-transition: all 0.25s ease-in-out;*/
			/*-o-transition: all 0.25s ease-in-out;*/
			/*-ms-transition: all 0.25s ease-in-out;*/
			/*transition: all 0.25s ease-in-out;*/
			/*margin-right: 0;*/
		}
		/* ================== Mobile Sub Menu Expander Arrows  ================== */
		.wsmenu > .wsmenu-list > li > .wsmenu-click {
			border-right: 1px solid !important;
			/*cursor: pointer;*/
			/*display: block;*/
			/*height: 46px;*/
			/*position: absolute;*/
			left: 0 !important;
			/*top: 0;*/
			/*width: 49px;*/
			/*z-index: 10;*/
			right: auto;
			border-left: none !important;
			border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
		}

		.wsmenu > .wsmenu-list > li > .wsmenu-click > i {
			/*display: block;*/
			/*height: 23px;*/
			/*width: 25px;*/
			/*margin-top: 8px;*/
			/*margin-right: 13px;*/
			/*background-size: 25px;*/
			/*font-size: 21px;*/
			/*float: right;*/
			/*text-align: center;*/
		}

		.wsmenu > .wsmenu-list > li > .wsmenu-click.ws-activearrow > i {
			/*-webkit-transform: rotate(180deg);*/
			/*-moz-transform: rotate(180deg);*/
			/*-o-transform: rotate(180deg);*/
			/*-ms-transform: rotate(180deg);*/
			/*transform: rotate(180deg);*/
			/*margin-top: 15px;*/
		}

		.wsmenu .wsmenu-list > li > .wsshoptabing > .wsshopwp > .wstabitem > li > .wsmenu-click02 {
			/*border-left: 1px solid $grey;*/
			/*cursor: pointer;*/
			/*display: block;*/
			/*height: 45px;*/
			/*position: absolute;*/
			left: 0 !important;
			/*top: 0;*/
			/*width: 49px;*/
			/*z-index: 10;*/
			/*background-color: $grey;*/
			right: auto;
			border-left: none !important;
			border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
		}

		.wsmenu .wsmenu-list > li > .wsshoptabing > .wsshopwp > .wstabitem > li > .wsmenu-click02 i {
			/*display: block;*/
			/*height: 23px;*/
			/*width: 34px;*/
			/*margin-top: 7px;*/
			/*margin-right: 8px;*/
			/*background-size: 25px;*/
			/*font-size: 21px;*/
			/*color: rgba(0, 0, 0, 0.25);*/
			/*float: right;*/
			/*text-align: center;*/
		}

		.wsmenu .wsmenu-list > li > .wsshoptabing > .wsshopwp > .wstabitem > li > .wsmenu-click02.ws-activearrow02 > i {
			/*-webkit-transform: rotate(180deg);*/
			/*-moz-transform: rotate(180deg);*/
			/*-o-transform: rotate(180deg);*/
			/*-ms-transform: rotate(180deg);*/
			/*transform: rotate(180deg);*/
			/*margin-top: 15px;*/
		}

		.wsmenu .wsmenu-list > li > .wtsbrandmenu > .wsshoptabingwp > .wstabitem02 > li > .wsmenu-click03 {
			/*border-left: 1px solid $light-grey;*/
			/*cursor: pointer;*/
			/*display: block;*/
			/*height: 45px;*/
			/*position: absolute;*/
			/*right: 0;*/
			/*top: 0;*/
			/*width: 49px;*/
			/*z-index: 10;*/
			/*background-color: $light-grey;*/
		}

		.wsmenu .wsmenu-list > li > .wtsbrandmenu > .wsshoptabingwp > .wstabitem02 > li > .wsmenu-click03 i {
			/*display: block;*/
			/*height: 23px;*/
			/*width: 34px;*/
			/*margin-top: 11px;*/
			/*margin-right: 8px;*/
			/*background-size: 25px;*/
			/*font-size: 21px;*/
			/*color: rgba(0, 0, 0, 0.25);*/
			/*float: right;*/
			/*text-align: center;*/
		}

		.wsmenu .wsmenu-list > li > .wtsbrandmenu > .wsshoptabingwp > .wstabitem02 > li > .wsmenu-click03.ws-activearrow03 > i {
			/*-webkit-transform: rotate(180deg);*/
			/*-moz-transform: rotate(180deg);*/
			/*-o-transform: rotate(180deg);*/
			/*-ms-transform: rotate(180deg);*/
			/*transform: rotate(180deg);*/
		}
		/*End Media Query*/
	}
	/* Extra @Media Query*/
	@media only screen and (min-width: 992px) and (max-width:1262px) {
		.wsmenu > .wsmenu-list > li.wssearchbar {
			/*width: 21%;*/
		}

		.smllogo {
			/*width: 7%;*/
		}

		.wsmenu {
			/*width: 93%;*/
		}
	}
}
