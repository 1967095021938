﻿.three-icon-actions {

	.boxout {
		float: left;
		position: relative;
		width: 100%;
		padding-bottom: 100%;
		overflow: hidden;
		margin: 0;
		height: 100%;
		padding-bottom: 100%;

		.content {
			width: 100%;
			text-align: center;
			position: absolute;

			figure {
				margin-left: 0;
				display: inline-block;
				float: none;
				min-height: auto;
			}
		}
	}
}
