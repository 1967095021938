﻿.medical-form {
    background-color: #fff;

    padding: 20px 0 20px;

    margin: 40px 0;

    .disabled {
        opacity: 0.5;
        pointer-events: none;
    }
    .hidden {
        display: none;
    }

    select {
        position: relative;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none; /* Remove default arrow */
        border-color: #2b3968;
        &::-ms-expand {
            display: none; /* Hide the default arrow in Internet Explorer 10 and Internet Explorer 11 */
        }

        padding-right: 40px;

        background: url(/dist/assets/img/icons/arrow.PNG) #fff no-repeat right  top ;
    }

    .date-picker,.date-picker2 {
        padding-right: 40px;
        background: url(/dist/assets/img/icons/calendar.svg) #fff no-repeat right 10px top 8px;
    }

    input {
        border-color: #2b3968;
    }

    h2:empty {
        display: none;
    }

    h2,
    label,
    legend,
    em,
    p,
    .desire-vaccine-1 {
        color: #2b3968 !important;
        font-style: normal;
    }
    .validation-18 {
        color: #dc3545;
    }

    label,
    legend {
        font-size: 16px;
    }
    hr {
        background: #2b3968;
        margin-top: 2rem;
        
        margin-bottom: 3rem;
    }
    .btn {
        font-size: 18px;
        padding: 10px 40px;
        background-color: #2b3968 !important;
        color: #fff !important;
    }

    .input-validation-error {
        color: #dc3545;
        border: 1px solid #dc3545;
    }

    input[type="radio"],
    input[type="checkbox"] {
        & ~ label.is-invalid {
            display: none !important;
        }
        margin-right: 15px;
        // margin-top: 8px;

        // position: relative;

        &:after {
            // content: "";
            // position: absolute;
            // width: 22px;
            // height: 22px;
            // background: #fff;

            // left: 50%;
            // top: 50%;
            // margin-left: -11px;
            // margin-top: -11px;

            // border-radius: 100%;
        }
        // &:checked::after {
        //     border: 5px solid #fff;
        //     background: #2b3968;
        // }
        &.input-validation-error::after {
            //    border: 1px solid red;
        }
    }

    input[type="checkbox"] {
        &:after {
            //     border-radius: 5px;
        }
    }

    .field-validation-error span {
        color: #dc3545;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type="number"] {
        -moz-appearance: textfield;
    }

    .ProviderType-2 {
        margin-bottom: 1rem;
    }
    .phone-number-prefix {
        position: absolute;
        color: #495057;
        top: 39px;
        left: 10px;
        pointer-events: none;
    }

    #Mobilenumber {
        padding-left: 50px;
    }

    .form-group {
        position: relative;
    }
    .VaccinationAtJaha-1 .col-12 label{
        display: inline;
    }
    .VaccinationAtJaha-1 .col-12{
        margin-bottom: 10px;

    }

    .UserConfirmation label{
        display: inline;
    }
}

@media (max-width: 768px) {
    .ProviderType-2 {
        margin-bottom: 0;
    }
    .medical-form em {
        width: 100%;
    }
    .ProviderType-2 select {
        margin-bottom: 20px;
    }
}

[dir="rtl"] {


    .flatpickr-current-month .numInputWrapper {
        width: 10ch\0; 
    }
    .medical-form input[type="radio"],
    .medical-form input[type="checkbox"] {
        margin-left: 15px;

        margin-right: 0;
    }

    .medical-form .date-picker,.medical-form .date-picker2,
    .medical-form select {
        padding-right: 12px;
        padding-left: 40px;
        background-position: 10px;
    }
    .medical-form select {
        background-position: 0 0;
    }

    .medical-form .phone-number-prefix {
        direction: ltr;
    }
    .medical-form #Mobilenumber {
        direction: ltr;
        text-align: left;
    }
}

.flatpickr-months .flatpickr-month,
.flatpickr-current-month {
    height: 40px !important;
}

[dir="rtl"] .flatpickr-current-month .flatpickr-monthDropdown-months {
    direction: rtl;
}
