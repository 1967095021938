﻿.hero {
	.container {
		.profile-pic {
			img {
				height: 250px;
				width: 250px;
			}
		}
	}
}
