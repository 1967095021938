.hero {

	.profile-background {
		background-repeat: no-repeat;
		background-position: right bottom;
		position: relative;
		background-image: url(/dist/assets/img/rectangle_triangles.png);
		background-blend-mode: color-burn;
		background-color: $green;
	}

	.profile-square {
		background-color: transparent;
	}

	.container {
		.profile-pic {
			position: absolute;
			bottom: 0;

			img {
				height: 150px;
				width: 150px;
				border-radius: 225px;
				border: 7px solid $white;
			}
		}
	}
}

.profile-info {

	p.details {
		margin-bottom: 0;

		span {
			font-weight: 700;
		}
	}
	.social {
		&-icon {
			width: 36px;
		}
	}
}