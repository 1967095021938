﻿.pagination {
	&.alt {
		.page-link {
			border-color: $white;

			&.previous, &.next {
				color: $green;
				background-color: $white !important;
			}

			&.previous:hover, &.next:hover {
				color: $green;
			}
		}

		.page-item {

			&.active .page-link {
				border-color: $white;
			}

			&.disabled .page-link {
				border-color: $white;
			}

			&.disabled .previous, &.disabled .next {
				display: none;
			}
		}
	}

	.page-link {
		color: $dark-grey;
		background-color: $white;
		border-color: $grey;
		font-weight: 700;
		font-size: 16px;

		span.text {
			display: inline;
		}

		&.previous, &.next {
			color: $white;
			background-color: $green !important;
		}

		&.previous:hover, &.next:hover {
			color: $white;
			background-color: $white;
			border-color: $dark-grey;
		}

		&.range, &.results {
			padding: .5rem .75rem;
			color: $black;
		}

		&.results {
			border-color: transparent;
			font-weight: 400;
			padding-left: 0;

			&:hover {
				border-color: transparent;
				pointer-events: none;
				// Opinionated: remove the "hand" cursor set previously for .page-link
				cursor: not-allowed;
			}
		}

		&:hover {
			color: $dark-grey;
			background-color: $white;
			border-color: $dark-grey;
		}

		&:focus {
			z-index: 2;
			outline: 0;
			box-shadow: none;
		}
		// Opinionated: add "hand" cursor to non-disabled .page-link elements
		&:not(:disabled):not(.disabled) {
			cursor: pointer;
		}
	}

	.page-item {
		display: none;

		&.previous, &.next {
			display: block !important;
		}

		&:first-child {
			.page-link {
				border-radius: 0;
			}
		}

		&:last-child {
			.page-link {
				border-radius: 0;
			}
		}

		&.active .page-link {
			z-index: 1;
			color: $black;
			background-color: $white;
			border-color: $grey;
		}

		&.disabled .page-link {
			color: $grey;
			pointer-events: none;
			// Opinionated: remove the "hand" cursor set previously for .page-link
			cursor: not-allowed;
			background-color: $white;
			border-color: $grey;
		}
	}
}
