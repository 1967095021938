header {
	.top-nav {
		.navbar {
			padding: 0.5rem 1rem;

			.search {
				.input-group {
					background: $white;
					border-radius: 50px;
					margin-top: 5px;
					left: -20px;

					.form-control {
						background-color: transparent;
						border: 0 !important;

						&:focus {
							box-shadow: none;
						}
					}

					button.btn {
						border-radius: 1rem !important;
						border: none;
					}

					.btn {
						background-color: $white;
						margin: 0;
						padding: 0 5px 0 5px;
						color: #6ec2da;

						&::after {
							font-family: 'Font Awesome 5 Pro';
							font-size: 20px;
							font-weight: 300;
							font-style: normal;
							content: '\f002';
							margin: 0 5px 0 5px;
							padding: 0;
							position: relative;
							top: 0;
						}
					}
				}
			}

			.nav-item {
				.dropdown-menu {
					background-color: $blue;
					border: 0;

					.dropdown-item {
						color: $white;
					}
				}

				&:last-child {
					a {
						padding-right: 0;
					}
				}
			}
		}
	}
}
