//this is used in the 'JHAH.Web.UI\Views\Partials\NestedContent\_FindADoctor.cshtml' view and the 'JHAH.FED\app\react\physician\filter.jsx' react file
.find-doctor {
	background-repeat: no-repeat;
	position: relative;

	&.background-image {
		background-size: cover;
		background-position: center;

		&:before {
			position: absolute;
			content: " ";
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: block;
			z-index: 0;
			background-color: rgba(43, 57, 104, 0.9);
		}
	}

	&.default-background-image {
		background-position: right bottom;
		background-color: $blue;
		background-image: url(/dist/assets/img/rectangle_triangles.png);
		background-blend-mode: color-burn;
	}

	.container {

		h2 {
			color: $white;
		}

		p {
			color: $white;
		}

		form {
			label {
				color: $white;
			}

			select, input {
				color: #4A4A4A;
				border-radius: 0;
			}

			.btn {

				&.small {
					height: 38px;
					font-size: 1.2rem;
				}
			}
		}
	}
}

.sort-pagination {
	.sort {
		select {
			border-radius: 0;
			background-color: $white;
			width: auto;
		}
	}
}

.profiles-archive {
	padding: 40px 0 40px 0;

	&-physicians {
		background-color: $grey;
		padding-bottom: 20px;
		background-repeat: no-repeat;
		background-position: right bottom;
		position: relative;
		background-image: url(/dist/assets/img/rectangle_triangles.png);
		background-blend-mode: color-burn;
	}

	a {
		text-decoration: none;
	}

	.profile {
		border: 1px solid $light-grey;
		padding: 3rem;
		text-align: center;
		background-color: $white;
		height: 100%;

		&-animate {
			&:hover {
				transition: all 0.6s cubic-bezier(0.47, 0, 0.745, 0.715);
				box-shadow: 0 30px 30px -10px rgba(0, 0, 0, 0.2);
				-webkit-transform: scale(1.05);
				transform: scale(1.05);
				z-index: 1;
			}
		}

		img {
			max-height: 128px;
			border-radius: 64px;
		}

		h3 {
			margin: 1rem 0;
			font-size: 1.5rem;
			font-weight: 700;
			color: $blue;
		}

		p {
			margin-bottom: 0;
			font-weight: 300;
			color: $black;

			span {
				font-weight: 500;
			}
		}

		.link {
			margin-top: 1rem;
			display: block;
			color: $green;
			font-weight: 700;
		}
	}
}

//updated green boxout on other pages
.boxout {
	&.doctor {
		.content {
			height: 100%;
			padding: 2rem;
		}
	}
}
