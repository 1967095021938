﻿.location-details {
	.expand-all, .collapse-all {	  
		display: none;

	  &.active {
		padding-bottom: 0.5rem;
		display: inline-flex;
	  }
	}
	.collapse-visible {
	  display: inline-flex;
	}
	.only-mobile {
	  display: block;
	  width: 100%;
	}
	.only-desktop {
	  display: none;
	}
	.hours-contenitor {
	  display: flex;
	  width: 100%;
	  flex-wrap: wrap;
	  .hours-wrap {
		display: flex;
		flex-direction: column;
		width: 100%;
		&:first-child {
		  margin-right: 0%;
		}
		&:nth-child(2) {
		  margin-left: 0%;
		}
		&:only-child {
		  margin-right: 0%;
		}
	  }
	  .service {
		width: 100%;
		padding-bottom: 10px;
  
		&-info {
		  background-color: $blue;
		  color: $white;
		  padding: 5px;

		  &-container {
			  padding-top: 10px;
		  }
		}
	  }
	}
	.opening-hours {
	  margin-bottom: 2.4rem;
	  .title-and-status {
		background-color: $green;
		color: $white;
		text-transform: uppercase;
		font-size: 0.8rem;
		border-radius: 0.3rem;
  
		h3 {
		  font-size: 1.2rem;
		  color: $white;
		}
  
		p {
		  margin: 0 0 10px 0;
		}
		a {
		  padding: 0.5rem;
		  display: inline-block;
		  display: flex;
		  justify-content: space-between;
		}

		.title {
			color: $white;

			&:hover {
				color: $white;
				text-decoration: none;
			}
		}
  
		.openClose-icon {
		  width: 20px;
		  height: 20px;
		  display: flex;
		  justify-content: center;
		  align-items: center;
		  cursor: pointer;
		  .toggle {
			$w: 18px;
			$h: 2px;
			$transition: 0.3s all cubic-bezier(0.17, 0.67, 0.09, 0.97);
  
			width: $w;
			height: $h;
			background: $white;
			position: relative;
			transition: $transition;
  
			&::after {
			  content: '';
			  width: $w;
			  transition: $transition;
			  transition-delay: 0.1s;
			  height: $h;
			  position: absolute;
			  opacity: 0;
			  background: $white;
			  transform: rotate(90deg);
			  opacity: 1;
			}
		  }
		}
	  }
	  .card-title-expanded {
		.openClose-icon {
		  .toggle {
			&::after {
			  opacity: 0;
			}
  
			&.open {
			  transform: rotate(-90deg);
			}
		  }
		}
	  }
	  h3 {
		font-size: 1.2rem;
		text-transform: uppercase;
	  }
  
	  .table {
		&-sm-down,
		&-md-up {
		  tr,
		  th,
		  td {
			&.active {
			  background-color: $green;
			  color: $white;
			}
		  }
  
		  th,
		  td {
			text-align: center;
		  }
  
		  th {
			&.active {
			  position: relative;
			}
  
			&.active:after {
			  content: '';
			  position: absolute;
			  top: 0;
			  left: 40%;
			  width: 0;
			  height: 0;
			  display: block;
			  border-left: 10px solid transparent;
			  border-right: 10px solid transparent;
			  border-top: 10px solid #ffffff;
			}
		  }
  
		  td {
			ul {
			  margin-bottom: 0;
  
			  li {
				&.divider {
				  hr {
					display: block;
					height: 1px;
					border: 0;
					border-top: 1px solid $green;
					margin: 5px auto 2px;
					width: 90%;
  
					&.active {
					  border-top: 1px solid $white;
					}
				  }
				}
			  }
			}
		  }
		}
  
		&-sm-down {
		  th,
		  td {
			text-align: left;
		  }
		}
  
		&-md-up {
		  th {
			padding-top: 10px;
		  }
  
		  td {
			padding-bottom: 10px;
		  }
		}
	  }
	}
  
	.map {
	  position: relative;
	  width: 100%;
  
	  &:after {
		content: '';
		display: block;
		padding-bottom: 100%;
	  }
  
	  .content {
		position: absolute;
		width: 100%;
		height: 100%;
		border: 1px solid $grey !important;
  
		iframe {
		  width: 100% !important;
		  height: 100% !important;
		}
	  }
	}
  
	&-tabbed-content {
	  display: block !important;
  
	  .location-details {
		margin-bottom: 15px !important;
	  }
  
	  .nav {
		&-pills {
		  .nav-link {
			color: $white;
			background-color: $green;
		  }
		}
	  }
  
	  .dropdown-item {
		&:hover,
		&.focus,
		&.active,
		&:active {
		  color: $white;
		  background-color: $green;
		}
	  }
  
	  .nav-tabs {
		border-color: $grey !important;
  
		.nav-item {
		  .nav-link {
			color: $blue !important;
			border-top-width: 3px !important;
  
			&.active,
			&:hover {
			  border-top-width: 3px !important;
			  border-color: $green $grey $white !important;
			}
		  }
		}
	  }
  
	  .tab-content {
		margin-top: 0 !important;
  
		.panel-collapse {
		  &.collapse {
			display: block;
			border: 1px solid $grey !important;
			border-top: 1px solid $grey !important;
		  }
		}
	  }
  
	  &-accordions {
		display: block;
	  }
	}
  }
  