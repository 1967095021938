﻿.video {

	p {
		a {
			color: $green;
			text-decoration: underline;
		}
	}

	video {
		width: 100%;
	}

	.virtual-tour-link {
		display: inline-block;
		position: relative;

		&:after {
			content: "";
			width: 100%;
			height: 100%;
			background: url(/dist/assets/img/icons/virtual-tour.svg);
			background-size: 80px 80px;
			background-repeat: no-repeat;
			background-position: center center;
			opacity: 0.8;
			top: 0;
			left: 0;
			position: absolute;
			display: inline-block;
		}
	}
}
