﻿.image-actions {
	.boxout-icon {
		height: auto;
		padding-bottom: 0 !important;

		.content {
			text-align: left;
			position: relative;
			padding: 2%;

			figure {
				margin: 0 auto 21px;
				display: block;
				float: left;
			}

			h2 {
				padding: 0;
			}
		}
	}
}
