header {
	z-index: 5555;
	position: relative;
	background: $white;

	.top-nav {
		background-color: $blue;
		color: $white;

		.navbar {
			padding: 0.2rem 1rem;

			.nav-link {
				color: $white;
			}
		}
	}

	.main-logo {
		padding: 90px 0 20px 0;

		a {
			margin: 0 auto;
			display: block;
			max-width: 394px;
		}
	}
}

//Mobile switch
.wsmobileheader {
	background-color: $blue;
	height: 55px;

	.nav-link {
		color: white;
		display: inline-block;
		padding: 12px 15px 12px 15px;
		margin: 0;
		float: left;
		height: 55px;

		&:hover {
			color: white;
		}
	}
}