﻿.hero {
	height: 470px;

	.square {

		.content {
			padding: 30px;

			h1 {
				font-size: 4.5rem;
				line-height: 4.9rem;
			}

			p {
				font-size: 1rem;
				line-height: 1.2rem;
			}
		}
	}
}
