﻿[dir="rtl"] {
	.promotional-panel {
		.image {
			margin-left: -50px;
		}

		.details {
			margin-left: 50px;
		}

		.content {

			.cta {
				text-align: right;

				.secondary-link {
					margin: 0 20px 20px 0;
				}
			}
		}
	}
}
