﻿.boxouts {
	.boxout {
		.content {
			figure {
				text-align: left;
			}
		}

		&-align-center {
			.content {
				figure, h2, h3, p {
					text-align: left !important;
				}
			}
		}
	}

	&-three {
		.boxout {
			.content {
				padding: 2%;
			}
		}
	}
}
