﻿[dir="rtl"] {
	.quiz {

		.btn-group > .btn-group:not(:last-child) > .btn,
		.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
			margin-left: 20px;
			margin-right: 0;
		}
	}
}
