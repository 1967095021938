﻿.boxouts {
	.boxout {
		height: 100%;
		margin-bottom: 15px;
		position: relative;

		.content {
			height: 100%;

			figure {
				text-align: center;
				margin-bottom: 20px;

				img {
					height: 95px;
				}
			}

			h2, h3 {
				font-weight: 700;
			}

			p {
				font-weight: 400;
			}
		}

		&-align-center {
			.content {
				figure, h2, h3, p {
					text-align: center !important;
				}
			}
		}
	}

	&-three {
		.boxout {
			.content {
				padding: 15% 5%;
			}
		}
	}

	&-two {
		.boxout {
			.content {
				padding: 10% 5%;
			}
		}
	}
}
