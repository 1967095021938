﻿[dir="rtl"] {
	.btn {

		&::after {
			margin: 0 14px 0 0;
		}

		&-arrow::after {
			content: '\f0a8';
		}
	}
}
