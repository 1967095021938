﻿.three-icon-actions {

	.boxout {

		.content {
			text-align: left;
			position: absolute;
			position: relative;
			padding: 2%;

			figure {
				margin: 0 auto 21px;
				display: block;
				float: left;
			}

			h2 {
				padding: 0;
			}
		}
	}
}
