﻿.tabbed-content {
	display: block;

	.header-image {
		height: 370px;
	}

	&-accordions {
		display: none;
	}
}
