﻿//bootstrap 4 ships with 'custom forms' https://getbootstrap.com/docs/4.0/components/forms/#custom-forms but unfortuantly buttons arent covered...
//so if you put a button next to say a 'custom-select' it isn't the right height (see https://github.com/twbs/bootstrap/issues/20809)
//this fixes it ;)
.custom-button {
	padding: 4px 40px 7px 40px !important;
}

button, button.btn, input[type="submit"], input[type="reset"] {
	border-radius: 0.3rem;
	border-color: $dark-grey;
	padding: 2px 40px 6px 40px;
	font-size: 1rem;
	font-weight: 700;
}

.btn {
	border-radius: 1.15rem;
	font-size: 1rem;
	font-weight: 700;
	padding: 2px 17px 5px 18px;
	text-decoration: none !important;
	white-space: normal; //this is overriding the bootstrap default... it means buttons wrap onto two lines...
	&:focus,
	&.focus {
		outline: 0;
		box-shadow: none;
	}

	&::after {
		font-style: normal;
		font-size: 20px;
		position: relative;
		top: 1px;
		margin: 0 0 0 14px;
	}

	&-link {
		border-width: 0;
	}

	&-arrow::after {
		font-family: 'Font Awesome 5 Pro';
		content: '\f0a9';
	}
	&-arrow-2::after {
		font-family: 'Font Awesome 5 Pro';
		content: '\f054';
	}

	&-chevron::after {
		content: '›';
	}

	&-search::after {
		font-family: 'Font Awesome 5 Pro';
		font-weight: 300;
		content: '\f002';
		margin: 0 0 0 14px;
	}

	&-download::after {
		font-family: 'Font Awesome 5 Pro';
		content: '\f0ab';
	}

	&-green {
		color: $white !important;
		background-color: $green;
		border-color: $green;

		&:hover {
			color: $white !important;
		}
	}

	&-blue {
		color: $white !important;
		background-color: $blue;
		border-color: $blue !important;

		&:hover {
			color: $white !important;
		}
	}

	&-grey {
		color: $blue !important;
		background-color: $light-grey !important;
		border-color: $blue;

		&:hover {
			color: $blue !important;
		}
	}
	&-grey-2 {
		color: $blue !important;
		background-color: #EFEFEF !important;
		border-color: #969696;
		
		&:hover { 
			color: $blue !important;
		}
	}

	&-white {
		color: $green !important;
		background-color: $white !important;
		border-color: $white;

		&:hover {
			color: $green !important;
		}
	}

	&-white-keyline {
		color: $white !important;
		background-color: transparent;
		border-color: $white !important;

		&:hover {
			color: $white !important;
		}
	}

	&-green-keyline {
		color: $green !important;
		background-color: transparent;
		border-color: $green !important;

		&:hover {
			color: $green !important;
		}
	}

	&-blue-keyline {
		color: $blue !important;
		background-color: transparent;
		border-color: $blue !important;

		&:hover {
			color: $blue !important;
		}
	}

	&-white-opacity {
		color: $dark-blue !important;
		background-color: rgba(255, 255, 255, 0.6);
		border-color: rgba(255, 255, 255, 0);

		&:hover {
			color: $white !important;
		}
	}

	&-green-text {
		color: $green !important;

		&:hover {
			color: $green !important;
		}
	}

	&-less-radius{
		border-radius: 5px;
		padding: 4px 17px 6px 18px;
	}
	&-blue-text {
		color: $blue !important;

		&:hover {
			color: $blue !important;
		}
	}

	&-white-text {
		color: $white !important;

		&:hover {
			color: $white !important;
		}
	}

	&-white-2{
		background: $white;
		color: #2B3968;
		&:hover{
			color: #2B3968;
		}
	}
}
