﻿[dir="rtl"] {
	.pagination {
		.page-link {

			&.previous {
				.fa-chevron-left::before {
					content: "\f054" !important;
				}
			}

			&.next {
				.fa-chevron-right::before {
					content: "\f053" !important;
				}
			}
		}
	}
}
