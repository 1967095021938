﻿.hero {
	.container {
		.profile-pic {
			img {
				height: 290px;
				width: 290px;
			}
		}
	}
}

.profile {
	&-hero {
		.square {
			transform: translateY(0);
		}
	}
}
