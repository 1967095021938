﻿// base css taken from an idea at https://codepen.io/geoffyuen/pen/FCBEg

$breakpoint-alpha: 576px;

.table:not(.table-ignore-responsive) {

	th {
		display: none;
	}

	td {
		display: block;

		&:last-child {
			padding-bottom: 0;
		}

		&:before {
			content: attr(data-th)": ";
			font-weight: 700;
			width: 30%;
			display: inline-block;

			@media (min-width: $breakpoint-alpha) {
				display: none;
			}
		}
	}

	th, td {
		@media (min-width: $breakpoint-alpha) {
			display: table-cell;
		}
	}

	td {
		@media (max-width: $breakpoint-alpha) {
			padding-left: 0 !important;

			&:last-child {
				padding-bottom: 2em;
			}
		}
	}
}
