.campaign-header-block {
	.content {
		margin-bottom: 100px;

		h2 {
			font-weight: 100;
			font-size: 2.5rem;
			margin-top: 100px;
			margin-bottom: 20px;
		}

		h3 {
			font-size: 1.2rem;
		}
	}
}