﻿.three-icon-actions {
	.boxout {
		padding: 20px 20px 0px 20px;
		border: 1px solid $blue;

		.content {
			text-align: center;

			a:hover {
				text-decoration: none;
			}

			figure {
				margin: 0 auto;

				img {
					height: 95px;
				}
			}

			h2 {
				color: $blue;
				padding: 10px 0 15px 0;
				font-weight: 700;
			}

			p {
				color: $black;
			}
		}
	}
}
