﻿.error-page {

	&-body {
		header .main-logo {
			margin-top: 0;
		}
	}

	h1 {
		color: #990000;
	}
}
