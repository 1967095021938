﻿.promotional-panel {
	margin-bottom: 130px;

	.image {
		margin-left: 50px;
	}

	.details {
		margin-left: -50px;
		margin-top: 50px;
	}

	.content {
		padding: 10%;

		h2 {
			margin: 45px 0 25px;
		}

		.cta {
			text-align: left;
			width: 100%;

			.secondary-link {
				margin: 0 0 20px 20px;
			}
		}
	}
}
