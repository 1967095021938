﻿.videos {
	background-color: $grey;
	color: $blue;
	padding: 40px 0 40px 0;

	.items {
		p {
			a, a:hover {
				color: $blue;
				text-decoration: none;
			}
		}

		.virtual-tour-link {
			display: block;
			position: relative;

			&:after {
				content: "";
				width: 100%;
				height: 100%;
				background: url(/dist/assets/img/icons/virtual-tour.svg);
				background-size: 80px 80px;
				background-repeat: no-repeat;
				background-position: center center;
				opacity: 0.8;
				top: 0;
				left: 0;
				position: absolute;
				display: block;
			}
		}

		.two:not(:first-child) {
		}

		.three:not(:first-child) {
		}
	}
}
