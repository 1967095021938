﻿[dir="rtl"] {
	.hero {
		.square {

			.content {

				h1 {
					font-size: 3rem;
					line-height: 1.4;
				}
			}
		}
	}
}
