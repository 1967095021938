footer {
	.footer-logo {
		width: 100%;
		float: none;
	}

	.social {
		width: 100%;
		float: none;
		text-align: left;
	}

	h5 {
		font-size: 24px;
		line-height: 29px;
	}

	p {
		font-size: 16px;
		line-height: 22px;
	}

	ul {
		li {
			a {
				font-size: 18px;
				line-height: 37px;
			}
		}
	}

	.signup-form {
		form {

			.btn {
				width: auto;
			}
		}
	}
}
