.wsmenu>.wsmenu-list>li>ul.sub-menu {
  opacity: 0;
  visibility: hidden;
  -o-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  -moz-transform-origin: 0% 0%;
  -webkit-transform-origin: 0% 0%;
  -o-transition: -o-transform 0.3s, opacity 0.3s;
  -ms-transition: -ms-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform: rotateX(-75deg);
  -o-transform: rotateX(-75deg);
  -moz-transform: rotateX(-75deg);
  -webkit-transform: rotateX(-75deg);
}

.wsmenu>.wsmenu-list>li:hover>ul.sub-menu {
  opacity: 1;
  visibility: visible;
  transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -webkit-transform: rotateX(0deg);
}

.wsmenu>.wsmenu-list>li>.wsmegamenu {
  opacity: 0;
  visibility: hidden;
  -o-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  -moz-transform-origin: 0% 0%;
  -webkit-transform-origin: 0% 0%;
  -o-transition: -o-transform 0.3s, opacity 0.3s;
  -ms-transition: -ms-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform: rotateX(-75deg);
  -o-transform: rotateX(-75deg);
  -moz-transform: rotateX(-75deg);
  -webkit-transform: rotateX(-75deg);
}

.wsmenu>.wsmenu-list>li:hover>.wsmegamenu {
  opacity: 1;
  visibility: visible;
  transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -webkit-transform: rotateX(0deg);
}

.wsmenu-list>li>.wsshoptabing {
  opacity: 0;
  visibility: hidden;
  -o-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  -moz-transform-origin: 0% 0%;
  -webkit-transform-origin: 0% 0%;
  -o-transition: -o-transform 0.3s, opacity 0.3s;
  -ms-transition: -ms-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform: rotateX(-75deg);
  -o-transform: rotateX(-75deg);
  -moz-transform: rotateX(-75deg);
  -webkit-transform: rotateX(-75deg);
}

.wsmenu-list>li:hover .wsshoptabing {
  opacity: 1;
  visibility: visible;
  transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -webkit-transform: rotateX(0deg);
}