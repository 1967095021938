/* Desktop Main Menu Color */

.headerfull {
	background-color: #fff;
}

.wsmenu > .wsmenu-list {
	background-color: #fff;
}

.wsmenu > .wsmenu-list > li > .navtext > span {
	color: $blue;
}

//this is the downward arrow in the nav
.wsmenu > .wsmenu-list > li > .navtext > span:after {
	color: $green;
}

//this is the main nav item e.g. 'care services'
.wsmenu > .wsmenu-list > li > a {
	color: #fff;
	border-top: 2px solid $white;
}

.wsmenu > .wsmenu-list > li > a .wsarrow:after {
	border-top-color: #b3b3b3;
}

.wsmenu > .wsmenu-list > li > a:hover .wsarrow:after {
	border-top-color: #333;
}

.wsmenu > .wsmenu-list > li > a.active .wsarrow:after {
	border-top-color: #333;
}

.wsmenu > .wsmenu-list > li:hover > a .wsarrow:after {
	border-top-color: #333;
}

.wsmenu > .wsmenu-list > li > a > .fa {
	color: #898d91;
}

.wsmenu > .wsmenu-list > li > a.active .fa {
	color: #333;
}

.wsmenu > .wsmenu-list > li:hover > a > .fa {
	color: #333;
}

.wsmenu > .wsmenu-list > li > a:hover .fa {
	color: #333;
}

.wsmenu > .wsmenu-list > li > a.active {
	background-color: #f5f5f5;
	color: #333;
}

//this is the hover state for the main nav item e.g. 'care services'
.wsmenu > .wsmenu-list > li:hover > a {
	background-color: $grey;
	border-top: 2px solid $blue;
	color: #333;
}

.wsmenu > .wsmenu-list > li > ul.sub-menu > li:hover > a {
	background-color: #f5f5f5;
	color: #333;
	text-decoration: none;
}

.wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li:hover > a {
	background-color: #f5f5f5;
	color: #333;
}

//this is the sub nav item e.g. 'primary care'
.wsmenu > .wsmenu-list > li > .wsshoptabing > .wsshopwp > .wstabitem > li.wsshoplink-active > a {
	background-color: $grey;
	color: $green;
	font-weight: 700;
}

/* Mobile Header Color */

.wsmobileheader {
	background-color: #fff;
}

.wsactive .wsmobileheader {
}

.wsmenu > .wsmenu-list > li > .wsmenu-click {
	border-color: rgba(0, 0, 0, 0.1);
}

.wsmenu > .wsmenu-list > li > .wsmenu-click > i {
	color: rgba(0, 0, 0, 0.25);
}