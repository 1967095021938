﻿.image-actions {
	.boxout {
		padding-bottom: 100% !important;

		&-rectangle {
			padding-bottom: 50% !important;

			.content {
				height: auto !important;
			}
		}

		.content {
			position: absolute;
			height: auto;
			padding: 20px 20px 0 20px;
		}
	}

	.boxout-icon {
		float: left;
		position: relative;
		width: 100%;
		padding-bottom: 100%;
		overflow: hidden;
		margin: 0;
		height: 100%;
		padding-bottom: 100%;

		.content {
			width: 100%;
			text-align: left;
			position: absolute;

			figure {
				margin-left: 0;
				display: inline-block;
				float: none;
				min-height: auto;

				img {
					max-height: 95px;
				}
			}
		}
	}
}
