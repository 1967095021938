.hero {

	.image {
		height: 250px;
	}

	.square {

		.content {
			padding: 20px;
		}
	}
}
