﻿.promotional-panel {
	.boxout {
		float: left;
		position: relative;
		width: 100%;
		overflow: hidden;
		margin: 0;
		border: 0;
		height: 100%;
		padding-bottom: 0;
	}

	.image {
		min-height: 300px;
	}

	.details {
		box-shadow: 0 0 30px -20px rgba(0, 0, 0, 0.75);
		position: relative;
		z-index: 0;
		transition: all 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
		background-image: url(../assets/img/square_triangles.png);
		background-repeat: no-repeat;
		background-position: bottom;
		background-position: bottom right;
		background-size: contain;

		&:hover {
			transition: all 0.6s cubic-bezier(0.47, 0, 0.745, 0.715);
			box-shadow: 0 30px 30px -10px rgba(0, 0, 0, 0.2);
			-webkit-transform: scale(1.05);
			transform: scale(1.05);
			z-index: 1;
		}
	}

	.content {
		padding: 10% 5%;
		height: 100%;
		width: 100%;
		position: relative;
		background-size: cover;
		background-position: center;

		.category {
			border-bottom: 2px solid $white;
			text-transform: uppercase;
			font-size: 0.8rem;
			letter-spacing: 1px;
			font-weight: 300;
			padding-bottom: 2px;
		}

		h2 {
			font-weight: bold;
			margin-top: 25px;
			margin-bottom: 25px;
			font-weight: 700;
		}

		.p {
			font-weight: 300;

			a {
				font-weight: bold;
			}
		}

		.cta {
			margin-top: 20%;
			text-align: center;

			.primary-link {
				margin: 0 0 20px 0;
			}

			.secondary-link {
				margin: 0 0 20px 0;
			}
		}
	}
}
