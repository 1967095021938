﻿.header-block-overlay {
	.row {
		background-color: rgba(48, 129, 122, 0);
	}

	.content {
		background-color: rgba(48, 129, 122, 0.95);
		padding: 5%;

		h1 {
			font-size: 4.5rem;
			line-height: 4.9rem;
		}

		.cta {
			margin-top: 20%;
		}
	}
}
