﻿/*
	the default sizing utilites that ship with bs 4 don't have breakpoint support...

	https://getbootstrap.com/docs/4.0/utilities/sizing/

	it's been requested but for the moment, we're using a css version suggested by 'wilbertcaba' in this post:

	https://github.com/twbs/bootstrap/issues/21943

	the one suggested by 'madmax' is more sassy but requires the bootstrap variables...

	the only addition to the sass is the 'w-auto' and 'w-{breakpoint}-auto' style
*/
.w-auto {
	width: auto !important;
}

@media (min-width:576px) {
	.w-sm-100 {
		width: 100% !important;
	}

	.w-sm-80 {
		width: 80% !important;
	}

	.w-sm-75 {
		width: 75% !important;
	}

	.w-sm-60 {
		width: 60% !important;
	}

	.w-sm-50 {
		width: 50% !important;
	}

	.w-sm-40 {
		width: 40% !important;
	}

	.w-sm-25 {
		width: 25% !important;
	}

	.w-sm-20 {
		width: 20% !important;
	}

	.w-sm-auto {
		width: auto !important;
	}
}

@media (min-width:768px) {
	.w-md-100 {
		width: 100% !important;
	}

	.w-md-80 {
		width: 80% !important;
	}

	.w-md-75 {
		width: 75% !important;
	}

	.w-md-60 {
		width: 60% !important;
	}

	.w-md-50 {
		width: 50% !important;
	}

	.w-md-40 {
		width: 40% !important;
	}

	.w-md-25 {
		width: 25% !important;
	}

	.w-md-20 {
		width: 20% !important;
	}

	.w-md-auto {
		width: auto !important;
	}
}

@media (min-width:992px) {
	.w-lg-100 {
		width: 100% !important;
	}

	.w-lg-80 {
		width: 80% !important;
	}

	.w-lg-75 {
		width: 75% !important;
	}

	.w-lg-60 {
		width: 60% !important;
	}

	.w-lg-50 {
		width: 50% !important;
	}

	.w-lg-40 {
		width: 40% !important;
	}

	.w-lg-25 {
		width: 25% !important;
	}

	.w-lg-20 {
		width: 20% !important;
	}

	.w-lg-auto {
		width: auto !important;
	}
}

@media (min-width:1200px) {
	.w-xl-100 {
		width: 100% !important;
	}

	.w-xl-80 {
		width: 80% !important;
	}

	.w-xl-75 {
		width: 75% !important;
	}

	.w-xl-60 {
		width: 60% !important;
	}

	.w-xl-50 {
		width: 50% !important;
	}

	.w-xl-40 {
		width: 40% !important;
	}

	.w-xl-25 {
		width: 25% !important;
	}

	.w-xl-20 {
		width: 20% !important;
	}

	.w-xl-auto {
		width: auto !important;
	}
}
